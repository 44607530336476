import Slider, { SliderProps } from '@material-ui/core/Slider';
import { UnitBase } from 'hooks/useDisplayUnits/useDisplayUnits';
import { UnitSystem, CustomUnitFields } from 'providers/api';
import { ChangeEvent } from 'react';
import { round } from 'utils';
import { isCustomUnitField } from 'utils/unitConversions';

interface UnitSystemNumberSliderProps extends SliderProps {
  unitSystem: UnitSystem;
  unitInfo: UnitBase;
  min: number;
  max: number;
  decimalPlaces: number;
  customUnitFields: CustomUnitFields[];
  onSliderChange: (event: ChangeEvent<{}>, newValue: number | number[]) => void
}

const UnitSystemNumberSlider = (props: UnitSystemNumberSliderProps) => {
  const { value, unitSystem, customUnitFields, unitInfo, min, max, decimalPlaces, onSliderChange, ...other } = props;
  const checkedValue = value ?? min;
  const isCustom = isCustomUnitField(unitSystem, customUnitFields, unitInfo.name);

  const handleOnChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    const currentValue = Array.isArray(newValue) ? newValue[0] : newValue;
    const unitSystemBaseValue = isCustom ? unitInfo.metric.conversion(currentValue ?? 0) : currentValue;

    onSliderChange(event, unitSystemBaseValue ?? 0);
  };

  return (
    <Slider
      {...other}
      value={isCustom ? round(unitInfo.imperial.conversion(checkedValue as number), decimalPlaces) : checkedValue}
      onChange={handleOnChange}
      valueLabelDisplay="auto"
      min={isCustom ? round(unitInfo.imperial.conversion(min), 0) : min}
      max={isCustom ? round(unitInfo.imperial.conversion(max), 0) : max}
    />
  );
};

export default UnitSystemNumberSlider;
