import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import DataDownload from 'components/DataTransactionBox/DataDownload';
import { HOUR_IN_MILLISECONDS } from 'config';
import { RheologyDataSummaryDto, useRheologyDataExport } from 'providers/api';
import { replace } from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ROUTE_PATH as EditRheologyPageRoute,
  ROUTE_PARAM_IDENTIFIER,
  ROUTE_RHEOLOGY_DATA_IDENTIFIER
} from './constants';
import useSaveRheologyData from '../../../../shared/useSaveRheologyData';

const useStyles = makeStyles({
  buttonLowerCase: {
    textTransform: 'none',
    paddingLeft: 0,
    fontWeight: 'normal',
    '&:hover': {
      background: 'unset',
    },
  },
});

interface RheologyDataSummaryActionMenuProps {
  projectName: string;
  rheologyDataSummary: RheologyDataSummaryDto;
  mineModelId: string;
  onDelete: (rheologyDataSummary: RheologyDataSummaryDto) => void;
}

const RheologyDataSummaryActionMenu = ({ projectName, rheologyDataSummary, mineModelId, onDelete }: RheologyDataSummaryActionMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [triggerRheologyDataExport, setTriggerRheologyDataExport] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const editPath = EditRheologyPageRoute;
  const rheologyDataExport = useRheologyDataExport(
    {
      mineModelId,
      rheologyDatasetId: rheologyDataSummary.rheologyDataSetId!,
    },
    {
      staleTime: HOUR_IN_MILLISECONDS,
      enabled: triggerRheologyDataExport,
      onSettled: () => setTriggerRheologyDataExport(false),
    },
  );

useSaveRheologyData(
    () => { },
    mineModelId,
    projectName,
    rheologyDataSummary.rheologyDataSetId,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = () => {
    const rheologyDataToPass = rheologyDataExport.data;
    const path = replace(
      ROUTE_RHEOLOGY_DATA_IDENTIFIER,
      rheologyDataSummary.rheologyDataSetId,
      replace(
        ROUTE_PARAM_IDENTIFIER,
        rheologyDataSummary.mineModelId,
        editPath,
      ),
    );
    history.push({
      pathname: path,
      state: { rheologyData: rheologyDataToPass },
    });
  };

  const handleDelete = () => {
    onDelete(rheologyDataSummary);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls={`project-actions-menu-${rheologyDataSummary.reference}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`rheology-data-summary-actions-menu-${rheologyDataSummary.reference}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleUpdate}>
          <ListItemIcon>
            <ViewCompactIcon fontSize="small" />
          </ListItemIcon>
          Update
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <DataDownload
            data={rheologyDataExport.data}
            fileName={`${rheologyDataSummary.reference ?? 'rheology-data'}.json`}
            fileFormat="json"
            buttonValue="Download Data"
            buttonProps={{
              variant: 'text',
              className: classes.buttonLowerCase,
            }}
            downloadData={() => {
              setTriggerRheologyDataExport(true);
              setAnchorEl(null);
            }}
          />
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          <Typography color="error" variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default RheologyDataSummaryActionMenu;
