import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ProgressButton from 'components/ProgressButton';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  PourNoteApprovalDto,
  useApprovePourNoteMutation,
  useApproverApprovePourNoteMutation,
  useApproverUnapprovePourNoteMutation,
  useResendPourNoteApprovalMutation
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import CreateApproverForm from '../CreateApproverForm';
import ApprovalTable from './ApprovalTable';

interface AwaitingApprovalAreaProps {
  pourReference: string;
  pourId: string;
  pourNoteId: string;
  pourNoteApprovers: PourNoteApprovalDto[];
  complete: boolean;
  isPdf: boolean;
  includeRoutePoints: boolean
}

const AwaitingApprovalArea = ({
  pourReference,
  pourId,
  pourNoteId,
  pourNoteApprovers,
  complete,
  isPdf,
  includeRoutePoints,
}: AwaitingApprovalAreaProps) => {
  const approvePourNoteMutation = useApprovePourNoteMutation(pourId);
  const approverApprovePourNoteMutation = useApproverApprovePourNoteMutation(pourId);
  const approverUnapprovePourNoteMutation = useApproverUnapprovePourNoteMutation(pourId);
  const resendPourNoteApprovalMutation = useResendPourNoteApprovalMutation(pourId);

  const handleApprove = () => {
    approvePourNoteMutation.mutate({ pourId, pourNoteId, pourReference, isPdf, includeRoutePoints });
  };

  const handleApproverApprove = (email: string, image?: File) => {
    approverApprovePourNoteMutation.mutate({
      pourId,
      pourNoteId,
      email,
      ...(image && {
        signature: {
          fileName: image.name,
          data: image,
        },
      }),
    });
  };

  const handleApproverUnapprove = (email: string) => {
    approverUnapprovePourNoteMutation.mutate({
      pourId,
      pourNoteId,
      command: {
        email,
      },
    });
  };

  const handleResendEmails = (approvers: string[]) => {
    resendPourNoteApprovalMutation.mutate({
      pourId,
      pourNoteId,
      form: {
        approvers,
      },
    });
  };

  const approvedCount = pourNoteApprovers.filter((approver) => approver.status === 20).length;

  return (
    <ProjectAuthorise
      permission={Permission.ManagePours}
      render={(isAuthorised) => (
        <>
          {(!complete && isAuthorised) && (
            <>
              <Typography variant="subtitle1">Add Approver to Pour Note</Typography>
              <CreateApproverForm pourId={pourId} pourNoteId={pourNoteId} disabled={complete} />
            </>
          )}

          <Typography variant="subtitle1">{`Approved (${approvedCount}/${pourNoteApprovers.length})`}</Typography>
          <Box component={Paper} mb={2}>
            <ApprovalTable
              pourId={pourId}
              data={pourNoteApprovers}
              onApproverApprove={handleApproverApprove}
              onApproverUnapprove={handleApproverUnapprove}
              onResendEmails={handleResendEmails}
              isEditable={!complete && isAuthorised}
            />
          </Box>

          {(!complete && isAuthorised) && (
            <Box component={Paper} p={2} mb={2}>
              <ProgressButton
                loading={approvePourNoteMutation.isLoading}
                onClick={handleApprove}
                disabled={approvedCount !== pourNoteApprovers.length}
                color="primary"
                fullWidth
                variant="contained"
              >
                Approve
              </ProgressButton>
            </Box>
          )}
        </>
      )}
    />
  );
};

export default AwaitingApprovalArea;
