/* eslint-disable no-mixed-operators */
interface GenerateMassBalanceFromTonnageInputs {
  totalTonnage: number;
  binderConcentration: number,
  tailingsSolidsDensity: number,
  binderParticleDensity: number,
  totalDryConcentrationByMass: number, // massConcentration - percentage of solids in the total weight
  carrierFluidDensity: number, // Normally water .995
  isDryTonnage: boolean;
}

interface GenerateMassBalanceFromTonnageOutputs {
  totalDryTonnage: number;
  totalWetTonnage: number;
  carrierFluidTonnage: number;
  binderDryTonnage: number;
  tailingsDryTonnage: number;
  binderDryFlowRate: number;
  tailingsDryFlowRate: number;
  totalDryFlowRate: number;
  carrierFluidFlowRate: number;
  totalFlowRate: number;
  totalDrySolidsDensity: number;
  slurryDensity: number;
}

interface WetTonnagesProps {
  totalWetTonnage: number;
  totalDryConcentrationByMass: number;
  binderConcentration: number;
}

interface WetTonnagesOutput {
  totalDryTonnage: number;
  carrierFluidTonnage: number;
  binderDryTonnage: number;
  tailingsDryTonnage: number;
}

const wetTonnages = ({
  totalWetTonnage,
  totalDryConcentrationByMass,
  binderConcentration,
}: WetTonnagesProps): WetTonnagesOutput => {
  const totalDryTonnage = totalWetTonnage * totalDryConcentrationByMass;
  const carrierFluidTonnage = totalWetTonnage - totalDryTonnage;
  const binderDryTonnage = totalDryTonnage * binderConcentration;
  const tailingsDryTonnage = totalDryTonnage - binderDryTonnage;
  return {
    totalDryTonnage, carrierFluidTonnage, binderDryTonnage, tailingsDryTonnage,
  };
};

interface DryTonnagesProps {
  totalDryConcentrationByMass: number;
  binderConcentration: number;
  tailingsDryTonnage: number;
}

interface DryTonnagesOutput {
  binderDryTonnage: number;
  totalDryTonnage: number;
  totalWetTonnage: number;
  carrierFluidTonnage: number;
}

const dryTonnages = ({
  totalDryConcentrationByMass,
  binderConcentration,
  tailingsDryTonnage,
}: DryTonnagesProps) => {
  const binderDryTonnage = (tailingsDryTonnage * binderConcentration) / (1 - binderConcentration);
  const totalDryTonnage = tailingsDryTonnage + binderDryTonnage;
  const totalWetTonnage = totalDryTonnage / totalDryConcentrationByMass;
  const carrierFluidTonnage = totalWetTonnage - totalDryTonnage;

  return {
    binderDryTonnage,
    totalDryTonnage,
    totalWetTonnage,
    carrierFluidTonnage,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const generateMassBalanceFromTonnage = ({
  totalTonnage,
  totalDryConcentrationByMass,
  binderConcentration,
  tailingsSolidsDensity,
  binderParticleDensity,
  carrierFluidDensity,
  isDryTonnage,
}: GenerateMassBalanceFromTonnageInputs): GenerateMassBalanceFromTonnageOutputs => {
  let adjustedDryConcentrationByMass = totalDryConcentrationByMass;
  let adjustedbinderConcentration = binderConcentration;

  // Check if adjustedDryConcentrationByMass is greater than 100% and divide by 100 if true
  if (adjustedDryConcentrationByMass > 1) {
    adjustedDryConcentrationByMass /= 100;
  }

  if (adjustedbinderConcentration > 1) {
    adjustedbinderConcentration /= 100;
  }

  // Tonnages
  const tonnages = isDryTonnage
    ? dryTonnages({ totalDryConcentrationByMass: adjustedDryConcentrationByMass, binderConcentration: adjustedbinderConcentration, tailingsDryTonnage: totalTonnage })
    : wetTonnages({ totalWetTonnage: totalTonnage, totalDryConcentrationByMass: adjustedDryConcentrationByMass, binderConcentration: adjustedbinderConcentration });

  // Flow Rates
  const binderDryFlowRate = tonnages.binderDryTonnage / (binderParticleDensity / 1000);
  const tailingsDryFlowRate = (isDryTonnage ? totalTonnage : (tonnages as WetTonnagesOutput).tailingsDryTonnage) / (tailingsSolidsDensity / 1000);
  const totalDryFlowRate = binderDryFlowRate + tailingsDryFlowRate;
  const carrierFluidFlowRate = tonnages.carrierFluidTonnage / (carrierFluidDensity / 1000);
  const totalFlowRate = totalDryFlowRate + carrierFluidFlowRate;

  // Densities
  const totalDrySolidsDensity = (tonnages.totalDryTonnage / totalDryFlowRate) * 1000;
  const slurryDensity = ((isDryTonnage ? (tonnages as DryTonnagesOutput).totalWetTonnage : totalTonnage) / totalFlowRate) * 1000;

  return {
    binderDryFlowRate,
    tailingsDryFlowRate,
    totalDryFlowRate,
    carrierFluidFlowRate,
    totalFlowRate,
    totalDrySolidsDensity,
    slurryDensity,
    totalDryTonnage: tonnages.totalDryTonnage,
    carrierFluidTonnage: tonnages.carrierFluidTonnage,
    binderDryTonnage: tonnages.binderDryTonnage,
    tailingsDryTonnage: isDryTonnage ? totalTonnage : (tonnages as WetTonnagesOutput).tailingsDryTonnage,
    totalWetTonnage: isDryTonnage ? (tonnages as DryTonnagesOutput).totalWetTonnage : totalTonnage,
  };
};
