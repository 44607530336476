import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { throughputUnitLabelFromThroughputControlType } from 'hooks/useDisplayUnits/display';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import {
  BackfillRecipeDto,
  CustomUnitFields,
  ThroughputControlType,
  UnitSystem,
  UcsCoefficientsDto
} from 'providers/api';
import UnitSystemDetail from './UnitSystemDetail';

interface RecipeDetail {
  key: string;
  design: string;
  selected: string;
}

interface RecipePasteFillDetailsProps {
  recipe: BackfillRecipeDto;
  unitSystemPreference: UnitSystem;
  throughputControlType: ThroughputControlType;
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; }
  customUnitFields : CustomUnitFields[];
}

const RecipePasteFillDetails = ({ recipe, unitSystemPreference, throughputControlType, targetDaysCoefficients, customUnitFields }: RecipePasteFillDetailsProps) => {
  const rows: RecipeDetail[] = [
    {
      key: 'Days',
      design: `${recipe.pasteSpecification?.targetDays ?? (Object.keys(targetDaysCoefficients ?? {})[0] as unknown as number)} days`,
      selected: `${recipe.pasteSpecification?.targetDays ?? (Object.keys(targetDaysCoefficients ?? {})[0] as unknown as number)} days`,
    },
    {
      key: 'Concentration',
      design: `${recipe.pasteOptimisedSpecification?.massConcentration} %m`,
      selected: `${recipe.pasteSelectedSpecification?.massConcentration} %m`,
    },
    {
      key: 'Binder',
      design: `${recipe.pasteOptimisedSpecification?.binderContent} %`,
      selected: `${recipe.pasteSelectedSpecification?.binderContent} %`,
    },
  ];

  return (
    <>
      <UnitSystemDetail
        designed={recipe.pasteSpecification?.throughput}
        selected={recipe.pasteSpecification?.throughput}
        unitSystem={unitSystemPreference}
        unitInfo={throughputUnitLabelFromThroughputControlType(throughputControlType)}
        decimalPlaces={2}
        customUnitFields={customUnitFields}
      />

      <UnitSystemDetail
        designed={recipe.pasteSpecification?.pumpPressure}
        selected={recipe.pasteSelectedSpecification?.pumpPressure}
        unitSystem={unitSystemPreference}
        unitInfo={unitLabels[BasicDisplayUnits.PressurePump]}
        decimalPlaces={1}
        customUnitFields={customUnitFields}
      />

      <UnitSystemDetail
        designed={recipe.pasteSpecification?.targetUCSStrength}
        selected={recipe.pasteSelectedSpecification?.ucsStrength}
        unitSystem={unitSystemPreference}
        unitInfo={unitLabels[BasicDisplayUnits.PressureUCS]}
        decimalPlaces={2}
        customUnitFields={customUnitFields}
      />

      {rows.map((row) => (
        <TableRow key={row.key}>
          <TableCell component="th" scope="row">{row.key}</TableCell>
          <TableCell align="right" width="auto">{row.design}</TableCell>
          <TableCell align="right">{row.selected}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default RecipePasteFillDetails;
