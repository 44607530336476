import { CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { BasePageProps } from 'modules/makePage';
import { useCreateRheologyMutation } from 'providers/api';
import { useHistory, useParams } from 'react-router-dom';
import * as ProjectsPageConstants from '../ProjectDetailsPage/constants';
import RheologyDataForm from '../../shared/NewRheologyPage/RheologyDataForm';
import { NewRheologyPageParams } from './constants';
import { NewRheologyDataQueries } from '../../shared/NewRheologyPage/useNewRheologyDataQueries';

const NewRheologyPage = ({ queries }: BasePageProps<NewRheologyDataQueries>) => {
  const { entityId } = useParams<NewRheologyPageParams>();
  const [projectQuery] = queries;
  const project = projectQuery && projectQuery.data;

  const history = useHistory();
  const mutation = useCreateRheologyMutation(entityId, {
    onSuccess: () => {
      history.push(ProjectsPageConstants.ROUTE_PATH);
    },
  });

  return (
    <Container maxWidth="lg">
      {!project ? (<CircularProgress />) : (<RheologyDataForm project={project} mutation={mutation} />)}
    </Container>
  );
};

export default NewRheologyPage;
