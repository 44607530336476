import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { throughputUnitLabelFromThroughputControlType } from 'hooks/useDisplayUnits/display';
import {
 BackfillRecipeDto,
 ThroughputControlType,
 UnitSystem,
 CustomUnitFields
} from 'providers/api';
import { round } from 'utils/number';
import UnitSystemDetail from './UnitSystemDetail';

interface RecipeDetail {
  key: string;
  design?: string;
  selected: string;
}

interface RecipeHydraulicFillDetailsProps {
  recipe: BackfillRecipeDto;
  unitSystemPreference: UnitSystem;
  throughputControlType: ThroughputControlType;
  customUnitFields : CustomUnitFields[];
}

const RecipeHydraulicFillDetails = ({ recipe, unitSystemPreference, throughputControlType, customUnitFields }: RecipeHydraulicFillDetailsProps) => {
  const rows: RecipeDetail[] = [
    {
      key: 'Concentration',
      design: `${round(recipe.hydraulicOptimisedSpecification?.massConcentration ?? 0, 2)} %m`,
      selected: `${round(recipe.hydraulicSelectedSpecification?.massConcentration ?? 0, 2)} %m`,
    },
    {
      key: 'Settling Velocity',
      selected: `${round(recipe.hydraulicSelectedSpecification?.settlingVelocity ?? 0, 2)} `,
    },
    {
      key: 'Minimum Velocity',
      selected: `${round(recipe.hydraulicSelectedSpecification?.minVelocity ?? 0, 2)}`,
    },
  ];

  return (
    <>
      <UnitSystemDetail
        designed={recipe.hydraulicSpecification?.throughput}
        selected={recipe.hydraulicSpecification?.throughput}
        unitSystem={unitSystemPreference}
        unitInfo={throughputUnitLabelFromThroughputControlType(throughputControlType)}
        decimalPlaces={2}
        customUnitFields={customUnitFields}
      />

      <TableRow>
        <TableCell component="th" scope="row">Binder:Tailings</TableCell>
        <TableCell align="right" width="auto">{`1:${round(100 / ((recipe.hydraulicSpecification?.binderTailingsRatio ?? 0) * 100), 0)}`}</TableCell>
        <TableCell align="right">{`1:${round(100 / ((recipe.hydraulicSpecification?.binderTailingsRatio ?? 0) * 100), 0)}`}</TableCell>
      </TableRow>

      {rows.map((row) => (
        <TableRow key={row.key}>
          <TableCell component="th" scope="row">{row.key}</TableCell>
          <TableCell align="right" width="auto">{row.design}</TableCell>
          <TableCell align="right">{row.selected}</TableCell>
        </TableRow>
      ))}

    </>
  );
};

export default RecipeHydraulicFillDetails;
