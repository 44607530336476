import {
  AddApproverCommand, FileParameter,
  FileResponse,
  PagedListOfPourReportDto,
  PagerParameters, PourDataForm, PourDto,
  PourForm,
  PoursSearchParameters,
  RemoveApproverCommand,
  ResendPourNoteApprovalRequestCommand,
  StopeBackfillSummaryDto,
  UnapprovePourNoteApprovalCommand,
  UpdatePourDateCommand,
  UpdatePourVolumeCommand
} from './api';
import {
  makeCreateMutation, makeGetQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const POURS_KEY = 'pours';
const POUR_KEY = 'pour';
const PAGED_POURS_KEY = 'paged_pours';
export const STOPE_BACKFILLS_KEY = 'stopeBackfills';
const STOPE_BACKFILL_KEY = 'stopeBackfill';

export interface PourMutation {
  stopeId: string;
  recipeId: string;
  recipeReference: string;
  data: PourForm;
}
export const useCreatePourMutation = makeCreateMutation<string, PourMutation>(
  POURS_KEY,
  (api) => (mutation) => api.pours.create(mutation.recipeId, mutation.data),
  (mutation) => `A pour was created from the recipe referenced ${mutation.recipeReference}.`,
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);

const POUR_NOTE_KEY = 'pour-note';

export const useDownloadPourNote = makeGetQuery<FileResponse, { pourId: string, pourNoteId: string }>(
  POUR_NOTE_KEY,
  (api, { pourId, pourNoteId }) => () => api.pours.downloadPourNote(pourId, pourNoteId),
);

export const useUpdatePourDateMutation = makeUpdateMutation<void, UpdatePourDateCommand>(
  POUR_KEY,
  POURS_KEY,
  (api, pourId) => (mutation) => api.pours.updatePourDate(pourId, mutation),
  () => 'The pour date has been successfully updated.',
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY, POUR_NOTE_KEY],
);

export const useUpdatePourVolumeMutation = makeUpdateMutation<void, UpdatePourVolumeCommand>(
  POUR_KEY,
  POURS_KEY,
  (api, pourId) => (mutation) => api.pours.updatePourVolume(pourId, mutation),
  () => 'The pour volume has been successfully updated.',
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY, POUR_NOTE_KEY],
);

export interface PoursQueryParams {
  projectId: string;
  pourId?: string;
  stopeId: string;
}

export const usePoursQuery = makeListQuery<PoursQueryParams, PourDto[]>(
  POURS_KEY,
  (api, params) => () => api.pours.getAll(params.projectId, params.stopeId),
);

export interface ProjectPoursQueryParams extends PagerParameters, PoursSearchParameters {
  projectId: string;
}

export const useProjectPoursQuery = makeListQuery<ProjectPoursQueryParams, PagedListOfPourReportDto>(
  PAGED_POURS_KEY,
  (api, params) => () => api.pours.getAllByProject(params.projectId, params.page, params.pageSize,
    params.startDate?.toJSON(), params.endDate?.toJSON(), params.status, undefined, params.stopeId),
);

export const usePourQuery = makeGetQuery<PourDto, string>(
  POUR_KEY,
  (api, entityId) => () => api.pours.get(entityId),
);

export const useMineOverviewQuery = makeListQuery<string, StopeBackfillSummaryDto[]>(
  STOPE_BACKFILLS_KEY,
  (api, entityId) => () => api.pours.getMineOverview(entityId),
);

export interface StopeOverviewParams {
  projectId: string;
  stopeId: string;
}

export const useStopeOverviewQuery = makeGetQuery<StopeBackfillSummaryDto, StopeOverviewParams>(
  STOPE_BACKFILL_KEY,
  (api, params) => () => api.pours.getStopeOverview(params.projectId, params.stopeId),
);

interface PourNoteMutation {
  pourReference: string;
  pourId: string;
  pourNoteId?: string;
  isPdf?: boolean;
  includeRoutePoints: boolean;
}

export const useApprovePourNoteMutation = makeUpdateMutation<void, PourNoteMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.approvePourNote(mutation.pourId, mutation.pourNoteId ?? '', mutation.isPdf, mutation.includeRoutePoints),
  (mutation) => `The pour note has been approved for the pour referenced ${mutation.pourReference}.`,
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);

export const useCreatePourNoteQuery = makeUpdateMutation<void, PourNoteMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.createPourNote(mutation.pourId, mutation.isPdf, mutation.includeRoutePoints),
  (mutation) => `A pour note has been generated for the pour referenced ${mutation.pourReference}.`,
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);

interface PourNoteParams {
  pourId: string;
  pourNoteId: string;
}

const POUR_FILE = 'pour_file';
export const usePourNoteFileQuery = makeGetQuery<FileResponse, PourNoteParams>(
  POUR_FILE,
  (api, params) => () => api.pours.downloadPourNote(params.pourId, params.pourNoteId),
);

export interface PourAddApproverMutation {
  pourId: string;
  pourNoteId: string;
  addApprover: AddApproverCommand;
}

export const useCreateApproverMutation = makeUpdateMutation<void, PourAddApproverMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.addApproverToPourNote(mutation.pourId, mutation.pourNoteId, mutation.addApprover!),
  (mutation) => `${mutation.addApprover!.email} has been added from the approver list.`,
);

export interface PourRemoveApproverMutation {
  pourId: string;
  pourNoteId: string;
  removeApprover: RemoveApproverCommand;
}

export const useRemoveApproverMutation = makeUpdateMutation<void, PourRemoveApproverMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.removeApproverToPourNote(mutation.pourId, mutation.pourNoteId, mutation.removeApprover!),
  (mutation) => `${mutation.removeApprover!.email} has been removed from the approver list.`,
);

export const useSendForApprovalMutation = makeUpdateMutation<void, PourNoteParams>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.requestPourNoteApproval(mutation.pourId, mutation.pourNoteId),
  () => 'Pour note sent for approval.',
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);

export interface ApproverApprovePourNoteMutation {
  pourId: string;
  pourNoteId: string;
  email: string;
  signature?: FileParameter;
}

export const useApproverApprovePourNoteMutation = makeUpdateMutation<void, ApproverApprovePourNoteMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.approvePourNoteApproval(mutation.pourId, mutation.pourNoteId, mutation.email, mutation.signature),
  (mutation) => `The pour was successfully approved for ${mutation.email}.`,
);

export interface ApproverUnapprovePourNoteMutation {
  pourId: string;
  pourNoteId: string;
  command: UnapprovePourNoteApprovalCommand,
}

export const useApproverUnapprovePourNoteMutation = makeUpdateMutation<void, ApproverUnapprovePourNoteMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.unapprovePourNoteApproval(mutation.pourId, mutation.pourNoteId, mutation.command),
  (mutation) => `The pour was successfully un-approved for ${mutation.command.email}.`,
);

export interface ResendPourNoteApprovalMutation {
  pourId: string;
  pourNoteId: string;
  form: ResendPourNoteApprovalRequestCommand;
}

export const useResendPourNoteApprovalMutation = makeUpdateMutation<void, ResendPourNoteApprovalMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.resendPourNoteApproval(mutation.pourId, mutation.pourNoteId, mutation.form),
  () => 'Emails for pour note approvals successfully resent.',
);

export interface PourDataMutation {
  pourId: string;
  data: PourDataForm;
  operatorLogNoteFile?: FileParameter;
}

export const useCreatePourDataMutation = makeUpdateMutation<void, PourDataMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.completePour(
    mutation.pourId,
    mutation.operatorLogNoteFile,
    mutation.data.pourCompletedDate?.toJSON(),
    mutation.data.dryTonnage,
    mutation.data.massConcentration,
    mutation.data.binderContent,
    mutation.data.volume,
    mutation.data.totalBinder,
    mutation.data.comments,
    undefined,
    mutation.data.binderTailingsRatio,
  ),
  () => 'The completion data was successfully created.',
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);

export const useUpdatePourDataMutation = makeUpdateMutation<void, PourDataMutation>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.updatePourData(
    mutation.pourId,
    mutation.operatorLogNoteFile,
    mutation.data.pourCompletedDate?.toJSON(),
    mutation.data.dryTonnage,
    mutation.data.massConcentration,
    mutation.data.binderContent,
    mutation.data.volume,
    mutation.data.totalBinder,
    mutation.data.comments,
    undefined,
    mutation.data.binderTailingsRatio,
  ),
  () => 'The completion data was successfully created.',
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);

export const useVoidPourNoteMutation = makeUpdateMutation<void, PourNoteParams>(
  POURS_KEY,
  POUR_KEY,
  (api) => (mutation) => api.pours.voidPourNote(mutation.pourId, mutation.pourNoteId),
  () => 'The pour note has been successfully voided.',
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);

const POUR_ATTACHMENT_KEY = 'pour-attachment';

export const useDownloadPourAttachment = makeGetQuery<FileResponse, { pourId: string, fileId: string }>(
  POUR_ATTACHMENT_KEY,
  (api, { pourId, fileId }) => () => api.pours.downloadAttachment(pourId, fileId),
);

export const useAbandonPourMutation = makeUpdateMutation<void, string>(
  POURS_KEY,
  POUR_KEY,
  (api) => (pourId) => api.pours.abandonPour(pourId),
  () => 'The pour note has been successfully voided.',
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);

export const useUnapprovePourNoteMutation = makeUpdateMutation<void, string>(
  POURS_KEY,
  POUR_KEY,
  (api, pourId) => (pourNoteId) => api.pours.unapprovePourNote(pourId, pourNoteId),
  () => 'The pour note has been successfully voided.',
  [STOPE_BACKFILLS_KEY, STOPE_BACKFILL_KEY, PAGED_POURS_KEY],
);
