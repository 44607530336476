import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  FillType,
  PourDataDto,
  UnitSystem,
  useCreatePourDataMutation,
  useUpdatePourDataMutation,
  CustomUnitFields
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { FormActionType, resolveTemplate } from 'utils';
import {
  ROUTE_STOPE_POURS_TAB_SELECTED_PATH as PourRoutePath,
  ROUTE_PARAM_IDENTIFIER as projectParamIdentifier,
  STOPE_PARAM_IDENTIFIER as stopeParamIdentifier
} from 'modules/project/PourDetailsPage/constants';
import CompletionForm from './CompletionForm';
import CompletionSummary from './CompletionSummary';

interface ApproveAndCompleteAreaProps {
  pourId: string;
  pourData?: PourDataDto;
  unitSystemPreference: UnitSystem;
  fillType: FillType;
  customUnitFields: CustomUnitFields[];
}

const ApproveAndCompleteArea = ({
  pourId,
  pourData,
  unitSystemPreference,
  customUnitFields,
  fillType,
}: ApproveAndCompleteAreaProps) => {
  const history = useHistory();
  const { projectId, stopeId } = useParams<{ projectId: string, stopeId: string, pourId: string }>();
  const [editing, setEditing] = React.useState(false);
  const mutation = pourData ? useUpdatePourDataMutation(pourId, { onSuccess: () => setEditing(false) }) : useCreatePourDataMutation(pourId);

  const handleNavigate = () => {
    history.push(
      resolveTemplate(
        PourRoutePath,
        {
          [projectParamIdentifier]: projectId,
          [stopeParamIdentifier]: stopeId,
        },
      ),
    );
  };

  const submitFormHandler = (formActionType: FormActionType) => {
    if (formActionType === 'update') return;
    handleNavigate();
  };

  if (!pourData || editing) {
    return (
      <CompletionForm
        pourId={pourId}
        pourData={pourData}
        mutation={mutation}
        closeForm={() => setEditing(false)}
        unitSystemPreference={unitSystemPreference}
        fillType={fillType}
        customUnitFields={customUnitFields}
        submitForm={submitFormHandler}
      />
    );
  }

  return (
    <>
      <CompletionSummary pourId={pourId} pourData={pourData} unitSystemPreference={unitSystemPreference} fillType={fillType} customUnitFields={customUnitFields} />
      <ProjectAuthorise permission={Permission.ManagePours}>
        <Box display="flex" flexDirection="row-reverse" mt={2}>
          <Button variant="contained" color="secondary" onClick={() => setEditing(true)}>Update Completion Details</Button>
        </Box>
      </ProjectAuthorise>
    </>
  );
};

export default ApproveAndCompleteArea;
