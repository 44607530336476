import { LocalDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { FormikActions, FormSpacing } from 'components/Form';
import { additionalUnitLabels, BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import useForm from 'hooks/useForm';
import UnitSystemNumberField from 'modules/project/ProjectAreaPage/ProjectStopesPage/StopePanelPage/RecipesTab/RecipeForm/UnitSystemNumberField';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import { useProject } from 'modules/project/providers/project';
import {
  DisplayUnits,
  FileParameter,
  FillType,
  makePourDataForm,
  PourDataDto,
  PourDataForm,
  PourDataMutation,
  UnitSystem,
  CustomUnitFields
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { FormActionType, makeLocalDateTimeUtils } from 'utils';
import {
  boolean,
  number,
  object,
  SchemaOf,
  string
} from 'yup';
import ImageUpload from '../ImageUpload';
import PourAttachmentDownload from '../PourAttachmentDownload';

const pourDataCommandSchema: SchemaOf<PourDataForm> = object().shape({
  pourCompletedDate: object().shape({}).required('A pour completion date is required').nullable(),
  dryTonnage: number().min(1, 'Dry tonnage must be greater or equal to 1').typeError('Dry tonnage must be a number.'),
  massConcentration: number().min(1).typeError('Concentration rate must be a number.'),
  binderContent: number().typeError('Binder must be a number.'),
  volume: number().min(1).typeError('Volume must be a number.'),
  totalBinder: number().typeError('Total Binder must be a number.'),
  comments: string(),
  volumeRetainedAfterOutflow: number().typeError('Volume must be a number.'),
  binderTailingsRatio: number().typeError('Volume must be a number.'),
  _hasNewFile: boolean().notRequired(),
});

interface PourDataFormInputs extends Omit<PourDataForm, 'pourCompletedDate'> {
  pourCompletedDate: LocalDateTime | null;
}

interface CompletionFormProps {
  pourId: string;
  pourData?: PourDataDto;
  mutation: UseMutationResult<void, unknown, PourDataMutation, unknown>;
  unitSystemPreference: UnitSystem;
  fillType: FillType;
  customUnitFields: CustomUnitFields[];
  closeForm: () => void;
  submitForm: (actionType: FormActionType, data?: any) => void;
}

const CompletionForm = ({ pourId, pourData, mutation, unitSystemPreference, fillType, closeForm, customUnitFields, submitForm }: CompletionFormProps) => {
  const { state: { timeZone } } = useProject();
  const [updateFile, setUpdateFile] = React.useState<boolean>(false);
  const [newOperatorLogNoteFile, setNewOperatorLogNoteFile] = React.useState<FileParameter>();

  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const {
    formik,
    helpers,
  } = useForm<PourDataFormInputs>({
    mutation,
    formikConfig: {
      initialValues: {
        pourCompletedDate: pourData?.pourCompletedDate.toLocalDateTime() ?? null,
        dryTonnage: pourData?.dryTonnage,
        massConcentration: pourData?.massConcentration,
        binderContent: pourData?.binderContent,
        volume: pourData?.volume,
        binderTailingsRatio: pourData?.binderTailingsRatio,
        totalBinder: pourData?.totalBinder,
        comments: pourData?.comments,
      },
      onSubmit: (newPourData, { setSubmitting }) => {
        setSubmitting(true);
        const pourDataForm: PourDataMutation = {
          pourId,
          data: makePourDataForm(newPourData as PourDataForm),
          operatorLogNoteFile: newOperatorLogNoteFile,
        };
        mutation.mutate(pourDataForm, {
          onSettled: () => {
            setSubmitting(false);
            submitForm(pourData ? 'update' : 'new');
          },
        });
      },
      validationSchema: pourDataCommandSchema,
    },
  });

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setTouched,
  } = formik;

  const handlePourCompletedDateChange = (value: LocalDateTime | null) => {
    if (value) {
      const processedDateTime = value.withHour(0).withMinute(0).withSecond(0).withNano(0);
      setFieldValue('pourCompletedDate', processedDateTime);
    }
  };

  const handleCancelOperatorFileUpdate = () => {
    setUpdateFile(false);
    setNewOperatorLogNoteFile(undefined);
    setFieldValue('_hasNewFile', undefined);
  };

  const cancelAction: [string, JSX.Element] = ['cancel', (
    <>
      {pourData && (
        <Button variant="contained" color="secondary" onClick={closeForm}>
          Cancel
        </Button>
      )}
    </>
  )];

  React.useEffect(() => {
    setTouched({
      ...touched,
      pourCompletedDate: true,
    } as any);
  }, [values.pourCompletedDate]);

  return (
    <ProjectAuthorise
      permission={Permission.ManagePours}
      render={(isAuthorised) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="subtitle1">Operator Log File</Typography>
          {pourData?.operatorLogNoteFile && !updateFile ? (
            <>
              {pourData.operatorLogNoteFile && (
                <Box display="flex" component={Paper} p={2} mb={2}>
                  <PourAttachmentDownload pourId={pourId} fileDetails={pourData.operatorLogNoteFile} />
                </Box>
              )}
              {isAuthorised && (
                <Box display="flex" flexDirection="row-reverse" mt={2}>
                  <Button variant="contained" color="secondary" onClick={() => setUpdateFile(true)}>Update Operator Log File</Button>
                </Box>
              )}
            </>
          ) : (
            <>
              {isAuthorised && (
                <>
                  <Box display="flex" component={Paper} p={2} mb={2}>
                    <Box mr={2}>
                      <ImageUpload
                        label="Select File"
                        onNewImage={
                          (image: File) => {
                            setNewOperatorLogNoteFile({ data: image, fileName: image.name });
                            setFieldValue('_hasNewFile', true);
                          }
                        }
                      />
                    </Box>
                    <Box height={40} lineHeight="40px">
                      {newOperatorLogNoteFile?.fileName ?? 'No file selected'}
                    </Box>
                  </Box>
                  {pourData?.operatorLogNoteFile && (
                    <Box display="flex" flexDirection="row-reverse" mt={2}>
                      <Button variant="contained" color="secondary" onClick={handleCancelOperatorFileUpdate}>Cancel Update Operator Log File</Button>
                    </Box>
                  )}
                </>
              )}
            </>
          )}

          <Typography variant="subtitle1">Pour Completion Details</Typography>
          <Box component={Paper} p={2} mb={2}>
            <FormSpacing>
              <Box>
                <MuiPickersUtilsProvider utils={makeLocalDateTimeUtils(timeZone)}>
                  <KeyboardDatePicker
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    onClick={() => setDatePickerOpen(true)}
                    required
                    disabled={!isAuthorised}
                    fullWidth
                    format="dd/MM/yyyy"
                    mask="__/__/____"
                    placeholder="__/__/____"
                    id="pourCompletedDate"
                    name="pourCompletedDate"
                    label={`Pour Date (${timeZone})`}
                    value={values.pourCompletedDate}
                    maxDate={LocalDateTime.now(timeZone)}
                    onChange={handlePourCompletedDateChange}
                    onBlur={handleBlur}
                    error={helpers.hasError('pourCompletedDate')}
                    helperText={helpers.getErrorHelpText('pourCompletedDate')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{
                      autoComplete: 'off',
                      onClick: () => {
                        setDatePickerOpen(true);
                        setFieldValue('pourCompletedDate', null);
                      },
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box>
                <UnitSystemNumberField
                  unitSystem={unitSystemPreference}
                  customUnitFields={customUnitFields}
                  unitInfo={additionalUnitLabels[DisplayUnits.DrySolidFeedRate]}
                  decimalPlaces={2}
                  disabled={!isAuthorised}
                  type="number"
                  id="dryTonnage"
                  name="dryTonnage"
                  label="Dry Tonnage (t/h)"
                  value={values.dryTonnage}
                  onChange={(e) => setFieldValue('dryTonnage', e.target.value)}
                  onBlur={handleBlur}
                  error={helpers.hasError('dryTonnage')}
                  helperText={helpers.getErrorHelpText('dryTonnage')}
                />
              </Box>
              <Box>
                <TextField
                  disabled={!isAuthorised}
                  type="number"
                  fullWidth
                  id="massConcentration"
                  name="massConcentration"
                  label="Average Feed Solids Mass Concentration"
                  value={values.massConcentration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={helpers.hasError('massConcentration')}
                  helperText={helpers.getErrorHelpText('massConcentration')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%m</InputAdornment>,
                  }}
                />
              </Box>
              {
                fillType === FillType.Paste && (
                  <Box>
                    <TextField
                      disabled={!isAuthorised}
                      type="number"
                      fullWidth
                      id="binderContent"
                      name="binderContent"
                      label="Binder Content"
                      value={values.binderContent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={helpers.hasError('binderContent')}
                      helperText={helpers.getErrorHelpText('binderContent')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%b</InputAdornment>,
                      }}
                    />
                  </Box>
                )
              }
              {
                fillType === FillType.Hydraulic && (
                  <>
                    <Box>
                      <TextField
                        disabled={!isAuthorised}
                        type="number"
                        fullWidth
                        id="binderTailingsRatio"
                        name="binderTailingsRatio"
                        label="Binder:Tailings"
                        value={values.binderTailingsRatio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={helpers.hasError('binderTailingsRatio')}
                        helperText={helpers.getErrorHelpText('binderTailingsRatio')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%b</InputAdornment>,
                        }}
                      />
                    </Box>
                  </>
                )
              }
              <Box>
                <UnitSystemNumberField
                  unitSystem={unitSystemPreference}
                  customUnitFields={customUnitFields}
                  unitInfo={unitLabels[BasicDisplayUnits.Volume]}
                  decimalPlaces={2}
                  disabled={!isAuthorised}
                  id="volume"
                  name="volume"
                  label="Pour Volume"
                  value={values.volume}
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('volume', e.target.value)}
                  error={helpers.hasError('volume')}
                  helperText={helpers.getErrorHelpText('volume')}
                />
              </Box>
              <Box>
                <TextField
                  disabled={!isAuthorised}
                  type="number"
                  fullWidth
                  id="totalBinder"
                  name="totalBinder"
                  label="Total Binder"
                  value={values.totalBinder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={helpers.hasError('totalBinder')}
                  helperText={helpers.getErrorHelpText('totalBinder')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{unitSystemPreference === UnitSystem.Metric ? 't' : 'tn'}</InputAdornment>,
                  }}
                />
              </Box>
            </FormSpacing>
          </Box>

          <Typography variant="subtitle1">Additional Comments</Typography>
          <Box component={Paper} p={2}>
            <TextField
              disabled={!isAuthorised}
              fullWidth
              id="comments"
              name="comments"
              label="Comments"
              value={values.comments}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('comments')}
              helperText={helpers.getErrorHelpText('comments')}
              multiline
              rows={3}
            />
          </Box>

          {isAuthorised && (
            <Box mt={2}>
              <FormikActions
                formik={formik}
                mutation={mutation}
                submitText={pourData ? 'Update' : 'Complete'}
                right={['reset', 'submit']}
                left={[cancelAction]}
              />
            </Box>
          )}
        </form>
      )}
    />
  );
};

export default CompletionForm;
