import GuiState from '../GUI/GuiState';
import * as GConstants from '../../GConstants';

function drawWrappedText(
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number,
): void {
  const words: string[] = text.split(' ');
  let line: string = '';
  let customY = y;

  for (let n = 0; n < words.length; n += 1) {
      const testLine: string = `${line + words[n]} `;
      const metrics: TextMetrics = ctx.measureText(testLine);
      const testWidth: number = metrics.width;

      if (testWidth > maxWidth && n > 0) {
          ctx.fillText(line, x, customY);
          line = `${words[n]} `;
          customY += lineHeight;
      } else {
          line = testLine;
      }
  }

  ctx.fillText(line, x, customY);
}

function calculateLines(
  text: string,
  maxWidth: number,
  ctx: CanvasRenderingContext2D,
): number {
  const words: string[] = text.split(' ');
  let line: string = '';
  let lineCount: number = 0;

  for (let n = 0; n < words.length; n += 1) {
      const testLine: string = `${line + words[n]} `;
      const metrics: TextMetrics = ctx.measureText(testLine);
      const testWidth: number = metrics.width;

      if (testWidth > maxWidth && n > 0) {
          lineCount += 1;
          line = `${words[n]} `;
      } else {
          line = testLine;
      }
  }

  if (line) {
      lineCount += 1;
  }

  return lineCount;
}

const plotPipeColorKey = (guiState: GuiState) => {
  if (guiState.showPipeTypesStatus) {
  const { modelState: { pipes, pipeLegend, recipeState }, modelCanvas, pipeColorCanvas } = guiState;
  const modelContext = modelCanvas.getContext('2d');
  const telemetryCtx = pipeColorCanvas.getContext('2d');

  if (telemetryCtx && modelContext) {
    const canvasPadding = 100;
    const canvasPaddingBottomToAvoidEdge = canvasPadding / 2;
    const rowHeight = 16;
    const colWidth = 150;
    const padding = 3;
    const barTitle = 'Pipe Legend';
    const barRowHeight = 32;
    const rectX = 0;
    const rectY = 20;
    const barX = 0;
    const barY = 0;

    const totalPipes = Object.values(pipes).length;
    const legendHeight = rectY + totalPipes * (rowHeight * 2);
    pipeColorCanvas.height = legendHeight + canvasPadding;

    telemetryCtx.globalAlpha = 1;
    telemetryCtx.font = '10px Arial';
    telemetryCtx.textBaseline = 'top';
    telemetryCtx.textAlign = 'left';
    telemetryCtx.fillStyle = GConstants.DARK_BLUE;
    telemetryCtx.fillRect(barX, barY, colWidth, barRowHeight);
    telemetryCtx.fillStyle = GConstants.WHITE;
    telemetryCtx.fillText(barTitle, barX + padding, barY + padding);

    let boxHeight = 0;
    for (let i = 0; i < totalPipes; i += 1) {
      Object.values(pipes)[i].velocity = Object.values(pipes)[i].getVelocity(recipeState.wetFlowRate!);
      guiState.updateLegend();
      if (pipeLegend.length > 0) {
        const pipeName = pipeLegend[i][0]!;
        const pipeVelocity = pipeLegend[i][1]!;
        const rectangleX = 0;
        const rectangleY = i === 0 ? 20 + (rowHeight * i) : boxHeight;
        const x = rectX + padding;
        const y = rectangleY + padding;
        const maxWidth = colWidth - padding * 2;
        const text = `${pipeName}  ---  ${pipeVelocity}`;

        const numberOflines = calculateLines(text, colWidth - padding * 2, telemetryCtx);

        telemetryCtx.fillStyle = GConstants.PIPE_COLOURS[i];
        telemetryCtx.fillRect(rectangleX, rectangleY, colWidth, rowHeight * numberOflines);
        boxHeight = rectangleY + (rowHeight * numberOflines) - (numberOflines > 1 ? padding * numberOflines : 0);
        telemetryCtx.fillStyle = GConstants.BLACK;
        drawWrappedText(telemetryCtx, text, x, y, maxWidth, 10);
      }
    }

    modelContext.drawImage(
      pipeColorCanvas,
      pipeColorCanvas.width,
      guiState.screenHeight - pipeColorCanvas.height - canvasPaddingBottomToAvoidEdge,
    );
    }
  }
};

export default plotPipeColorKey;
