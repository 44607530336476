import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { FormikHelpers } from 'formik';
import { throughputUnitLabelFromThroughputControlType } from 'hooks/useDisplayUnits/display';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import { Form } from 'hooks/useForm';
import {
  BackfillRecipeForm,
  PourTypeDataDto,
  ThroughputControlType,
  UcsCoefficientsDto,
  UnitSystem,
  CustomUnitFields
} from 'providers/api';
import { sanitiseNumber } from 'utils/form';
import { DictionaryRange } from '../../../canvas/typesInterfaces';
import PourTypeSelectMenu from './PourTypeSelectMenu';
import UnitSystemNumberField from './UnitSystemNumberField';
import UnitSystemNumberSlider from './UnitSystemNumberSlider';

interface PasteTargetInputsProps {
  projectId: string;
  stopeId: string;
  isOptimised: boolean;
  values: BackfillRecipeForm;
  limits: DictionaryRange;
  maxPumpPressure: number;
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto } | undefined
  isSubmitting: boolean;
  isValid: boolean;
  unitSystemPreference: UnitSystem;
  customUnitFields: CustomUnitFields[];
  throughputControlType: ThroughputControlType;
  classes: ClassNameMap;
  handleBlur: (e: any) => void | void;
  setFieldValue: FormikHelpers<BackfillRecipeForm>['setFieldValue'];
  onTargetsUpdate: () => void;
  onReset: () => void;
  hasError: Form['helpers']['hasError'];
  getErrorHelpText: Form['helpers']['getErrorHelpText'];
  applyPourType: (pourType: PourTypeDataDto) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

const PasteTargetInputs = ({
  projectId,
  stopeId,
  isOptimised,
  values,
  limits,
  maxPumpPressure,
  targetDaysCoefficients,
  isSubmitting,
  isValid,
  unitSystemPreference,
  customUnitFields,
  throughputControlType,
  classes,
  handleBlur,
  setFieldValue,
  onTargetsUpdate,
  onReset,
  hasError,
  getErrorHelpText,
  applyPourType,
  setFieldTouched,
}: PasteTargetInputsProps) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Typography variant="caption">Paste Fill</Typography>
    </Grid>
    <Grid item xs={12}>
      <Box>
        <FormHelperText id="recipe-reference-helper-text" component="label">Recipe Reference</FormHelperText>
        <TextField
          size="small"
          error={hasError('reference')}
          helperText={getErrorHelpText('reference')}
          required
          autoFocus
          fullWidth
          id="reference"
          name="reference"
          aria-labelledby="recipe-reference-helper-text"
          value={values.reference}
          disabled={isOptimised}
          onChange={(e) => setFieldValue('reference', e.target.value)}
          onBlur={handleBlur}
        />
      </Box>
    </Grid>

    <Grid item xs={12}>
      <PourTypeSelectMenu projectId={projectId} stopeId={stopeId} onChange={applyPourType} disabled={isOptimised} />
    </Grid>

    {/* Required fields */}

    <Grid item xs={4}>
      <FormHelperText id="pump-pressure-helper-text" component="label">Pump Pressure</FormHelperText>
      <UnitSystemNumberField
        unitSystem={unitSystemPreference}
        customUnitFields={customUnitFields}
        unitInfo={unitLabels[BasicDisplayUnits.PressurePump]}
        value={values.pasteSpecification?.pumpPressure ?? limits.pumpPressure.default ?? 0}
        onChange={(event) => setFieldValue('pasteSpecification.pumpPressure', event.target.value)}
        decimalPlaces={0}
        size="small"
        id="pump-pressure"
        name="pasteSpecification.pumpPressure"
        error={hasError('specification.pumpPressure')}
        helperText={getErrorHelpText('specification.pumpPressure')}
        aria-labelledby="pump-pressure-helper-text"
        onBlur={handleBlur}
        disabled={isOptimised}
      />

    </Grid>
    <Grid item xs={8}>
      <Box ml={1} mr={2} pt={2}>
        <UnitSystemNumberSlider
          unitSystem={unitSystemPreference}
          customUnitFields={customUnitFields}
          unitInfo={unitLabels[BasicDisplayUnits.PressurePump]}
          value={values.pasteSpecification?.pumpPressure ?? limits.pumpPressure.default ?? 0}
          decimalPlaces={5}
          step={
            unitSystemPreference === UnitSystem.Metric
              ? 1
              : 5
          }
          min={0}
          max={maxPumpPressure}
          name="pasteSpecification.pumpPressure"
          onBlur={handleBlur}
          onSliderChange={(event, newValue) => setFieldValue('pasteSpecification.pumpPressure', newValue as number)}
          valueLabelDisplay="auto"
          disabled={isOptimised}
          aria-labelledby="pump-pressure-helper-text"
        />
      </Box>
    </Grid>

    <Grid item xs={4}>
      <FormHelperText id="tonnage-helper-text" component="label">
        { throughputUnitLabelFromThroughputControlType(throughputControlType).name }
      </FormHelperText>
      <UnitSystemNumberField
        unitSystem={unitSystemPreference}
        customUnitFields={customUnitFields}
        unitInfo={throughputUnitLabelFromThroughputControlType(throughputControlType)}
        disabled={throughputControlType !== ThroughputControlType.FlowRate}
        onChange={(event) => setFieldValue('pasteSpecification.throughput', event.target.value)}
        decimalPlaces={0}
        id="tonnage-input"
        size="small"
        name="tonnage-input"
        aria-labelledby="tonnage-helper-text"
        value={values.pasteSpecification?.throughput ?? limits.tons.default ?? limits.tons.min}
        error={hasError('specification.throughput')}
        helperText={getErrorHelpText('specification.throughput')}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: <InputAdornment position="end">t/h</InputAdornment>,
        }}
      />

    </Grid>
    <Grid item xs={8}>
      <Box ml={1} mr={2} pt={2}>
        <UnitSystemNumberSlider
          unitSystem={unitSystemPreference}
          customUnitFields={customUnitFields}
          unitInfo={throughputUnitLabelFromThroughputControlType(throughputControlType)}
          value={values.pasteSpecification?.throughput ?? limits.tons.default ?? limits.tons.min}
          decimalPlaces={0}
          min={limits.tons.min}
          step={
            unitSystemPreference === UnitSystem.Metric
              ? limits.tons.interval
              : limits.tons.interval
          }
          max={limits.tons.max}
          onSliderChange={(event: any, newValue: number | number[]) => setFieldValue('pasteSpecification.throughput', newValue as number)}
          valueLabelDisplay="auto"
          disabled={isOptimised}
          aria-labelledby="tonnage-helper-text"
          // TODO: Put marks back into slider (alcwyn)
          // marks={
          //   unitSystemPreference === UnitSystem.Metric
          //     ? limits.tons.data.map((value: number) => ({ value }))
          //     : limits.tons.data.map((value: number) => ({ value: unitLabels[BasicDisplayUnits.Tonnage].imperial.conversion(value) }))
          // }
        />
      </Box>
    </Grid>

    <Grid item xs={7}>
      <FormHelperText id="target-ucs-strength-helper-text" component="label">Target UCS</FormHelperText>
      <UnitSystemNumberField
        unitSystem={unitSystemPreference}
        customUnitFields={customUnitFields}
        unitInfo={unitLabels[BasicDisplayUnits.PressureUCS]}
        value={values.pasteSpecification?.targetUCSStrength}
        onChange={(event) => {
          setFieldValue('pasteSpecification.targetUCSStrength', sanitiseNumber(event.target.value));
          setFieldTouched('pasteSpecification.targetUCSStrength', true);
        }}
        decimalPlaces={2}
        error={hasError('pasteSpecification.targetUCSStrength')}
        helperText={getErrorHelpText('pasteSpecification.targetUCSStrength')}
        id="pasteSpecification.targetUCSStrength"
        name="pasteSpecification.targetUCSStrength"
        aria-labelledby="target-ucs-strength-helper-text"
        disabled={isOptimised}
        onBlur={handleBlur}
      />
    </Grid>
    <Grid item xs={1}>
      <div className={classes.at}>
        at
      </div>
    </Grid>
    <Grid item xs={4}>
      <FormHelperText id="target-days-helper-text" component="label">Target Days</FormHelperText>
      <TextField
        size="small"
        type="number"
        error={hasError('pasteSpecification.targetDays')}
        helperText={getErrorHelpText('pasteSpecification.targetDays')}
        fullWidth
        select
        id="pasteSpecification.targetDays"
        name="pasteSpecification.targetDays"
        aria-labelledby="target-days-helper-text"
        value={values.pasteSpecification?.targetDays ?? Object.keys(targetDaysCoefficients ?? {})[0]}
        disabled={isOptimised}
        onChange={(e) => setFieldValue('pasteSpecification.targetDays', parseInt(e.target.value, 10))}
        onBlur={handleBlur}
      >
        {Object.keys(targetDaysCoefficients ?? {}).map((numberOfDays) => <MenuItem key={`key-${numberOfDays}`} value={numberOfDays as string}>{numberOfDays}</MenuItem>)}
      </TextField>
    </Grid>

    <Grid item xs={12}>
      <Box pt={1} pb={1}>
        <Button
          fullWidth
          aria-label="add new recipe"
          variant="contained"
          color="primary"
          size="large"
          disabled={isSubmitting || !isValid}
          onClick={(e) => {
            e.preventDefault();
            isOptimised ? onReset() : onTargetsUpdate();
          }}
        >
          {isOptimised ? 'Re-optimise' : 'Optimise'}
        </Button>
      </Box>
    </Grid>
  </Grid>
);

export default PasteTargetInputs;
