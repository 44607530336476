import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import Actions from 'components/Actions';
import AlertText from 'components/AlertText';
import ConditionalButton from 'components/ConditionalButton';
import {
  ROUTE_PATH as NewPourRoutePath,
  ROUTE_PARAM_IDENTIFIER as projectParamIdentifier,
  RECIPE_PARAM_IDENTIFIER as recipeParamIdentifier,
  STOPE_PARAM_IDENTIFIER as stopeParamIdentifier
} from 'modules/project/NewPourPage/constants';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  BackfillRecipeDto,
  BackfillWarningDto,
  DisplayUnits,
  CustomUnitFields,
  FillType,
  RheologyDataDto, RheologyDataSummaryDto, ThroughputControlType, UnitSystem,
  useDeleteRecipeMutation,
  useMineModelRheologyDataListQuery,
  useRecipesQuery
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import { useConfirmation } from 'providers/confirm';
import { ConfirmationOptions } from 'providers/confirm/ConfirmDialog';
import {
  find,
  indexBy,
  prop,
  propEq
} from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { resolveTemplate } from 'utils';
import { MineModelApi } from '../../canvas/cmodel';
import { DictionaryRange } from '../../canvas/typesInterfaces';
import ListSkeleton from '../ListSkeleton';
import WarningTooltipIcon from '../WarningTooltipIcon';
import RecipeDetails from './RecipeDetails';
import RecipeErroredPlaceHolder from './RecipeErroredPlaceHolder';
import RecipeForm from './RecipeForm/RecipeForm';
import RheologyDataSetSelector from './RecipeForm/RheologyDataSetSelector';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: 'calc(100% - 190px)',
    overflowY: 'auto',
  },
  headerTitle: {
    maxWidth: 175,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  alignRight: {
    textAlign: 'right',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  accordianSummary: {
    position: 'relative',
  },
  accordionDetails: {
    display: 'block',
  },
  backdrop: {
    backgroundColor: 'rgb(48 48 48 / 70%)',
    position: 'absolute',
    zIndex: 0,
  },
}));

interface RecipeStatusProps {
  isStale: boolean;
  optimisedMassConcentration: number;
  selectedMassConcentration: number;
  optimisedBinderContent: number;
  selectedBinderContent: number;
}

const RecipeStatus = ({ isStale, optimisedMassConcentration, selectedMassConcentration, optimisedBinderContent, selectedBinderContent }: RecipeStatusProps) => {
  if (isStale) {
    return <Typography color="textPrimary" variant="caption">Stale</Typography>;
  }

  const isOptimised = optimisedMassConcentration === selectedMassConcentration && optimisedBinderContent === selectedBinderContent;

  const status = isOptimised ? 'Optimised' : 'Adjusted';

  return <Typography color="textPrimary" variant="caption">{status}</Typography>;
};

interface RecipeSummaryProps {
  warnings?: BackfillWarningDto[];
  reference: string;
  isStale: boolean;
  optimisedMassConcentration: number;
  selectedMassConcentration: number;
  optimisedBinderContent: number;
  selectedBinderContent: number;
}

const RecipeSummary = ({
  warnings,
  reference,
  isStale,
  optimisedMassConcentration,
  selectedMassConcentration,
  optimisedBinderContent,
  selectedBinderContent,
}: RecipeSummaryProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={7} className={classes.headerTitle}>
        <Box display="flex">
          {warnings && (
            <Box mr={1}>
              <WarningTooltipIcon warnings={warnings} />
            </Box>
          )}
          <Box flexGrow={1}>
            {reference}
          </Box>
        </Box>
      </Grid>
      <Grid className={classes.alignRight} item xs={4}>
        <RecipeStatus
          isStale={isStale}
          optimisedMassConcentration={optimisedMassConcentration}
          selectedMassConcentration={selectedMassConcentration}
          optimisedBinderContent={optimisedBinderContent}
          selectedBinderContent={selectedBinderContent}
        />
      </Grid>
    </Grid>
  );
};

const confirmDialogOptions = (recipe: BackfillRecipeDto): ConfirmationOptions => ({
  variant: 'danger',
  description: `Are you sure you want to remove '${recipe.reference}'?`,
});

const gatherWarnings = (recipes: BackfillRecipeDto[]): { [clientId: string]: BackfillWarningDto[] } => {
  const recipesWithWarnings = recipes.filter((recipe) => recipe.warnings && recipe.warnings.length > 0);
  let warnings = {};
  recipesWithWarnings.forEach((recipe) => {
    warnings = {
      ...warnings,
      [recipe.entityId]: recipe.warnings,
    };
  });
  return warnings;
};

interface RecipesTabPanelProps {
  projectId: string;
  rheologyDatasetList: RheologyDataSummaryDto[];
  stopeId: string;
  stopeName: string;
  stopeComplete: boolean;
  fillType: FillType;
  mineModelApi?: MineModelApi | null;
  displayUnitPreferences: DisplayUnits[];
  customUnitFields: CustomUnitFields[];
  unitSystemPreference: UnitSystem;
  selectedRouteSpoolIds: string[];
  initialRheologyDataset?: RheologyDataDto;
  throughputControlType: ThroughputControlType;
}

const RecipesTabPanel = ({
  projectId,
  rheologyDatasetList,
  stopeId,
  stopeName,
  stopeComplete,
  fillType,
  mineModelApi,
  displayUnitPreferences,
  customUnitFields,
  unitSystemPreference,
  selectedRouteSpoolIds,
  initialRheologyDataset,
  throughputControlType,
}: RecipesTabPanelProps) => {
  const history = useHistory();
  const classes = useStyles();
  const confirm = useConfirmation();
  const deleteMutation = useDeleteRecipeMutation();
  const { data: recipes, isLoading } = useRecipesQuery({ projectId, stopeId });

  const { isLoading: rheologyDataListIsLoading, data: rheologyDataList } = useMineModelRheologyDataListQuery(
    {
      mineModelId: projectId,
      rheologyDataSetIds: recipes ? recipes?.map((recipe) => recipe.rheologyDataSetId ?? '') : [],
    },
    {
      enabled: !!recipes && recipes.length > 0,
      onError: (error) => {
        console.log('now', error);
      },
    },
  );

  const rheologyDataListIndexedById = rheologyDataList ? indexBy(prop('rheologyDataSetId'), rheologyDataList) : {};

  const [recipeIdExpanded, setRecipeIdExpanded] = React.useState<string | null>(null);
  const [recipeIdEditing, setRecipeIdEditing] = React.useState<string | null>(null);
  const [recipeEditingIsOptimised, setRecipeEditingIsOptimised] = React.useState(true);
  const [newRecipe, setNewRecipe] = React.useState<boolean>(false);
  const [newRecipeIsOptimised, setNewRecipeIsOptimised] = React.useState(false);
  const [routeHasChanged, setRouteHasChanged] = React.useState(false);
  const [selectedRheologyDataset, setSelectedRheologyDataset] = React.useState<RheologyDataDto | undefined>(initialRheologyDataset);
  const [limits, setLimits] = React.useState<DictionaryRange>();

  const warnings = React.useMemo(() => gatherWarnings(recipes ?? []), [recipes]);
  const [liveWarnings, setLiveWarnings] = React.useState<BackfillWarningDto[]>();

  const updateMineModelVisuals = (recipeId: string) => {
    const recipe = find(propEq('entityId', recipeId), recipes ?? []);

    // send values of existing recipe
    if (!recipe || !recipes) return;

    if (fillType === FillType.Paste) {
      mineModelApi?.triggerSendPasteRecipe(
        recipe.pasteSelectedSpecification?.ucsStrength || 0,
        recipe.pasteSpecification?.targetDays || (Object.keys(selectedRheologyDataset?.ucsCoefficients ?? {})[0] as unknown as number),
        recipe.pasteSelectedSpecification?.massConcentration || 0,
        recipe.pasteSelectedSpecification?.binderContent || 0,
        recipe.pasteSelectedSpecification?.pumpPressure || 0,
        selectedRheologyDataset?.tailingsDrySolidsDensity,
        selectedRheologyDataset?.binderDryParticleDensity,
        selectedRheologyDataset?.carrierFluidDensity,
        throughputControlType === ThroughputControlType.DryTonnage ? recipe.pasteSpecification?.throughput : undefined,
        throughputControlType === ThroughputControlType.FlowRate ? recipe.pasteSpecification?.throughput : undefined,
        throughputControlType === ThroughputControlType.WetTonnage ? recipe.pasteSpecification?.throughput : undefined,
      );
    }

    if (fillType === FillType.Hydraulic) {
      mineModelApi?.triggerSendHydraulicRecipe(
        recipe.hydraulicSpecification?.binderTailingsRatio || 0,
        recipe.hydraulicSelectedSpecification?.massConcentration || 0,
        selectedRheologyDataset?.tailingsDrySolidsDensity,
        selectedRheologyDataset?.binderDryParticleDensity,
        selectedRheologyDataset?.carrierFluidDensity,
        throughputControlType === ThroughputControlType.DryTonnage ? recipe.hydraulicSpecification?.throughput : undefined,
        throughputControlType === ThroughputControlType.FlowRate ? recipe.hydraulicSpecification?.throughput : undefined,
        throughputControlType === ThroughputControlType.WetTonnage ? recipe.hydraulicSpecification?.throughput : undefined,
      );
    }
  };

  const handleAccordionChange = (recipeId: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setRecipeIdExpanded(isExpanded ? recipeId : null);
    setRecipeEditingIsOptimised(true);
    if (isExpanded) {
      const selectedRecipe = recipes?.find((recipe) => recipe.entityId === recipeId);
      if (selectedRecipe) {
        mineModelApi?.setSelectedRoute(selectedRecipe.spoolIds);
        updateMineModelVisuals(recipeId);
      }
    }
    if (!isExpanded) mineModelApi?.triggerClearPlots();
    setRouteHasChanged(false);
  };

  const handleNew = () => {
    setNewRecipeIsOptimised(false);
    setNewRecipe(true);
    setRecipeIdExpanded(null);
    setRecipeIdEditing(null);
  };

  const handleNewPour = (recipe: BackfillRecipeDto) => {
    history.push(
      resolveTemplate(
        NewPourRoutePath,
        {
          [projectParamIdentifier]: projectId,
          [stopeParamIdentifier]: stopeId,
          [recipeParamIdentifier]: recipe.entityId,
        },
      ),
    );
  };

  const handleRecipeWarning = (newWarnings: BackfillWarningDto[]) => {
    if (newWarnings && newWarnings.length > 0) {
      setLiveWarnings(newWarnings);
    } else {
      setLiveWarnings(undefined);
    }
  };

  const handleSuccess = (recipeId: string): void => {
    setNewRecipe(false);
    setRecipeIdEditing(null);
    setRecipeIdExpanded(recipeId);
  };

  const handleEdit = (recipeId: string) => {
    setRecipeIdEditing(recipeId);
    const selectedRecipe = recipes && recipes.find((r) => r.entityId === recipeId);
    const rheologyDataSet = selectedRecipe && rheologyDatasetList?.find((dataset) => dataset.rheologyDataSetId === selectedRecipe.rheologyDataSetId);
    rheologyDataSet && setSelectedRheologyDataset(rheologyDataSet as any);
    selectedRecipe && mineModelApi?.setSelectedRoute(selectedRecipe?.spoolIds);
  };

  const handleDelete = (recipe: BackfillRecipeDto) => {
    confirm(confirmDialogOptions(recipe))
      .then(() => deleteMutation.mutate(recipe));
  };

  const handleCancel = (recipeId?: string) => () => {
    if (recipeId) {
      updateMineModelVisuals(recipeId);
      setRecipeIdEditing(null);
    } else {
      setNewRecipe(false);
      mineModelApi?.triggerClearPlots();
    }
  };

  const handleNewRecipeIsOptimisedUpdate = (isOptimised: boolean) => {
    setNewRecipeIsOptimised(isOptimised);
  };

  const handleRecipeEditingIsOptimisedUpdate = (isOptimised: boolean) => {
    setRecipeEditingIsOptimised(isOptimised);
  };

  // when unloading this component deselect the current recipe in the model.
  React.useEffect(() => () => mineModelApi?.deselectRecipe(), []);

  React.useEffect(() => {
    setRouteHasChanged(true);
  }, [selectedRouteSpoolIds]);

  if (isLoading || rheologyDataListIsLoading) {
    return <ListSkeleton numberOfListItems={6} />;
  }

  if (!recipes) {
    return <AlertText severity="info">No recipes have been created.</AlertText>;
  }

  return (
    <div className={classes.root}>
      <ProjectAuthorise permission={Permission.ManageRecipes}>
        <Box mx={3} mb={2}>
          {
            selectedRouteSpoolIds && selectedRouteSpoolIds.length > 0 && (
              <Button
                fullWidth
                aria-label="add new recipe"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={() => handleNew()}
              >
                Add Recipe
              </Button>
            )
          }
          {
            (!selectedRouteSpoolIds || selectedRouteSpoolIds.length === 0) && (
              <Typography variant="caption">Select a route on the map to create a recipe.</Typography>
            )
          }

        </Box>
      </ProjectAuthorise>

      {/* Create recipe */}
      {newRecipe && (
        <Accordion expanded={newRecipe} onChange={() => setNewRecipe(false)}>
          <AccordionSummary
            expandIcon={(
              <IconButton>
                <CloseIcon />
              </IconButton>
            )}
            id="panel-new-header"
          >
            {liveWarnings && <WarningTooltipIcon warnings={liveWarnings} />}
            <Box pl={1}>
              <Typography variant="h6">
                New Stope Recipe
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%" display="flex" flexDirection="column">
              <Box mb={3}>
                {/* Consider moving this component along with the state that controls newRecipeIsOptimised into the RecipeForm.
                IsOptimised state was moved out of the RecipeForm to account for the disabled logic for RheologyDataSetSelector.
                Ideally this selector should be part of the RecipeForm */}
                <RheologyDataSetSelector
                  mineModelId={projectId}
                  rheologyDatasetList={rheologyDatasetList}
                  selectedRheologyDatasetId={selectedRheologyDataset?.rheologyDataSetId}
                  disabled={newRecipeIsOptimised}
                  onRheologyDataSetSelected={(newRheologyData: any) => {
                    setSelectedRheologyDataset(newRheologyData);
                    if (mineModelApi) {
                      setLimits(mineModelApi.updateSelectedRheologyData(newRheologyData, throughputControlType));
                    }
                  }}
                />
              </Box>

              {mineModelApi && selectedRheologyDataset && (
                <RecipeForm
                  selectedRouteSpoolIds={selectedRouteSpoolIds}
                  projectId={projectId}
                  stopeId={stopeId}
                  stopeName={stopeName}
                  rheologyDatasetList={rheologyDatasetList}
                  limits={limits ?? mineModelApi.limits}
                  maxPumpPressure={selectedRheologyDataset.pumpPressure.max ?? 2000}
                  fillType={fillType}
                  rheologyDataSetId={selectedRheologyDataset.rheologyDataSetId}
                  targetDaysCoefficients={selectedRheologyDataset.ucsCoefficients ?? {}}
                  specificGravity={selectedRheologyDataset.tailingsDrySolidsDensity}
                  heightOfCylinder={selectedRheologyDataset.heightOfCylinder}
                  mixerCoefficients={selectedRheologyDataset.mixerCoefficients}
                  yieldStressA={
                    selectedRheologyDataset.yieldStressCoefficients && Object.values(selectedRheologyDataset.yieldStressCoefficients)[0]
                      ? Object.values(selectedRheologyDataset.yieldStressCoefficients)[0]
                      : undefined
                  }
                  yieldStressB={
                    selectedRheologyDataset.yieldStressCoefficients && Object.values(selectedRheologyDataset.yieldStressCoefficients)[1]
                      ? Object.values(selectedRheologyDataset.yieldStressCoefficients)[1]
                      : undefined
                  }
                  tailingsSolidsDensity={selectedRheologyDataset.tailingsDrySolidsDensity}
                  carrierFluidDensity={selectedRheologyDataset.carrierFluidDensity}
                  binderParticleDensity={selectedRheologyDataset.binderDryParticleDensity}
                  displayUnitPreferences={displayUnitPreferences}
                  customUnitFields={customUnitFields}
                  unitSystemPreference={unitSystemPreference}
                  isOptimised={newRecipeIsOptimised}
                  triggerPasteRecipeFromStrength={mineModelApi.triggerPasteRecipeFromStrength}
                  calculateHydraulicOptimise={mineModelApi.triggerHydraulicFillRecipeFromStrength}
                  triggerPasteStrengthAndPressureFromRecipe={mineModelApi.triggerPasteStrengthAndPressureFromRecipe}
                  triggerHydraulicFillStrengthFromRecipe={mineModelApi.triggerHydraulicFillStrengthFromRecipe}
                  checkForWarnings={mineModelApi.checkWarningsForSelectedRoute}
                  onWarning={handleRecipeWarning}
                  onSuccess={handleSuccess}
                  onCancel={handleCancel()}
                  updateIsOptimised={handleNewRecipeIsOptimisedUpdate}
                  throughputControlType={throughputControlType}
                />
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {/* View, edit and delete recipes */}
      {!rheologyDataListIsLoading && recipes.map((recipe, index) => (
        <React.Fragment key={recipe.entityId}>
          {
            recipe.rheologyDataSetId && rheologyDataListIndexedById[recipe.rheologyDataSetId]
              ? (
                <Accordion expanded={recipeIdExpanded === recipe.entityId} onChange={handleAccordionChange(recipe.entityId)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id={`panel-${index}-header`}
                  >
                    <RecipeSummary
                      reference={recipe.reference}
                      warnings={recipe.entityId === recipeIdEditing ? liveWarnings : warnings?.[recipe.entityId]}
                      isStale={recipe.isStale}
                      optimisedMassConcentration={recipe.pasteOptimisedSpecification?.massConcentration ?? 0}
                      selectedMassConcentration={recipe.pasteSelectedSpecification?.massConcentration ?? 0}
                      optimisedBinderContent={recipe.pasteOptimisedSpecification?.binderContent ?? 0}
                      selectedBinderContent={recipe.pasteSelectedSpecification?.binderContent ?? 0}
                    />
                    {recipe.isStale && <Backdrop className={classes.backdrop} open />}
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Box>
                      {recipe.isStale && (
                        <Alert severity="warning">
                          The stope information has been modified after this recipe was created. To create a pour from this recipe
                          you are required to edit and re-optimise the values.
                        </Alert>
                      )}
                    </Box>
                    <Box>
                      {
                        recipeIdEditing === recipe.entityId
                          && mineModelApi
                          && recipe.rheologyDataSetId
                          && rheologyDataListIndexedById[recipe.rheologyDataSetId]
                          ? (
                            <>
                              <Box mb={3}>
                                {/* Consider moving this component along with the state that controls recipeEditingIsOptimised into the RecipeForm.
                                  IsOptimised state was moved out of the RecipeForm to account for the disabled logic for RheologyDataSetSelector.
                                  Ideally this selector should be part of the RecipeForm */}
                                <RheologyDataSetSelector
                                  mineModelId={projectId}
                                  rheologyDatasetList={rheologyDatasetList}
                                  selectedRheologyDatasetId={selectedRheologyDataset?.rheologyDataSetId}
                                  disabled={recipeEditingIsOptimised}
                                  onRheologyDataSetSelected={(newRheologyData: any) => {
                                    setSelectedRheologyDataset(newRheologyData);
                                    if (mineModelApi) {
                                      setLimits(mineModelApi.updateSelectedRheologyData(newRheologyData, throughputControlType));
                                    }
                                  }}
                                />
                              </Box>
                              <RecipeForm
                                selectedRouteSpoolIds={routeHasChanged ? selectedRouteSpoolIds : recipe.spoolIds}
                                stopeId={stopeId}
                                stopeName={stopeName}
                                rheologyDatasetList={rheologyDatasetList}
                                projectId={projectId}
                                recipe={recipe}
                                limits={limits ?? mineModelApi.limits}
                                maxPumpPressure={
                                  rheologyDataListIndexedById[recipe.rheologyDataSetId].pumpPressure.max
                                    ? rheologyDataListIndexedById[recipe.rheologyDataSetId].pumpPressure.max!
                                    : 200
                                }
                                fillType={fillType}
                                rheologyDataSetId={selectedRheologyDataset?.rheologyDataSetId ?? recipe.rheologyDataSetId}
                                targetDaysCoefficients={rheologyDataListIndexedById[recipe.rheologyDataSetId].ucsCoefficients ?? {}}
                                displayUnitPreferences={displayUnitPreferences}
                                customUnitFields={customUnitFields}
                                unitSystemPreference={unitSystemPreference}
                                specificGravity={rheologyDataListIndexedById[recipe.rheologyDataSetId].tailingsDrySolidsDensity}
                                heightOfCylinder={rheologyDataListIndexedById[recipe.rheologyDataSetId].heightOfCylinder}
                                mixerCoefficients={rheologyDataListIndexedById[recipe.rheologyDataSetId].mixerCoefficients}
                                yieldStressA={
                                  rheologyDataListIndexedById[recipe.rheologyDataSetId].yieldStressCoefficients.coefficient1
                                }
                                yieldStressB={
                                  rheologyDataListIndexedById[recipe.rheologyDataSetId].yieldStressCoefficients.coefficient2
                                }
                                tailingsSolidsDensity={rheologyDataListIndexedById[recipe.rheologyDataSetId].tailingsDrySolidsDensity}
                                carrierFluidDensity={rheologyDataListIndexedById[recipe.rheologyDataSetId].carrierFluidDensity}
                                binderParticleDensity={rheologyDataListIndexedById[recipe.rheologyDataSetId].binderDryParticleDensity}
                                isOptimised={recipeEditingIsOptimised}
                                triggerPasteRecipeFromStrength={mineModelApi.triggerPasteRecipeFromStrength}
                                calculateHydraulicOptimise={mineModelApi.triggerHydraulicFillRecipeFromStrength}
                                triggerPasteStrengthAndPressureFromRecipe={mineModelApi.triggerPasteStrengthAndPressureFromRecipe}
                                triggerHydraulicFillStrengthFromRecipe={mineModelApi.triggerHydraulicFillStrengthFromRecipe}
                                checkForWarnings={mineModelApi.checkWarningsForSelectedRoute}
                                onWarning={handleRecipeWarning}
                                onSuccess={handleSuccess}
                                onCancel={handleCancel(recipe.entityId)}
                                updateIsOptimised={handleRecipeEditingIsOptimisedUpdate}
                                throughputControlType={throughputControlType}
                              />
                            </>
                          ) : (
                            <>
                              <RecipeDetails
                                recipe={recipe}
                                displayUnitPreferences={displayUnitPreferences}
                                customUnitFields={customUnitFields}
                                unitSystemPreference={unitSystemPreference}
                                throughputControlType={throughputControlType}
                                targetDaysCoefficients={rheologyDataListIndexedById[recipe.rheologyDataSetId].ucsCoefficients ?? {}}
                                specificGravity={recipe.rheologyDataSetId ? rheologyDataListIndexedById[recipe.rheologyDataSetId].tailingsDrySolidsDensity : undefined}
                                mixerCoefficients={recipe.rheologyDataSetId ? rheologyDataListIndexedById[recipe.rheologyDataSetId].mixerCoefficients : undefined}
                                heightOfCylinder={recipe.rheologyDataSetId ? rheologyDataListIndexedById[recipe.rheologyDataSetId].heightOfCylinder : undefined}
                                yieldStressA={
                                  recipe.rheologyDataSetId
                                    ? rheologyDataListIndexedById[recipe.rheologyDataSetId].yieldStressCoefficients.coefficient1
                                    : undefined
                                }
                                yieldStressB={
                                  recipe.rheologyDataSetId
                                    ? rheologyDataListIndexedById[recipe.rheologyDataSetId].yieldStressCoefficients.coefficient2
                                    : undefined
                                }
                                tailingsSolidsDensity={rheologyDataListIndexedById[recipe.rheologyDataSetId].tailingsDrySolidsDensity}
                                carrierFluidDensity={rheologyDataListIndexedById[recipe.rheologyDataSetId].carrierFluidDensity}
                                binderParticleDensity={rheologyDataListIndexedById[recipe.rheologyDataSetId].binderDryParticleDensity}
                                fillType={fillType}
                              />
                              <Box mt={1}>
                                <ProjectAuthorise
                                  permission={Permission.ManageRecipes}
                                  render={(isAuthorised) => (
                                    <>
                                      {isAuthorised ? (
                                        <Actions
                                          left={[
                                            ['delete', (
                                              <Button className={classes.deleteButton} onClick={() => handleDelete(recipe)} type="submit">
                                                Delete
                                              </Button>
                                            )],
                                            ['edit', (
                                              <Button onClick={() => handleEdit(recipe.entityId)} type="submit">
                                                Edit
                                              </Button>
                                            )],
                                          ]}
                                          right={[
                                            ['create', (
                                              <ConditionalButton
                                                color="primary"
                                                onClick={() => handleNewPour(recipe)}
                                                disabled={stopeComplete || recipe.isStale}
                                                disabledReason={stopeComplete ? 'Stope is complete' : 'This recipe is stale and has been disabled'}
                                              >
                                                Create Pour
                                              </ConditionalButton>
                                            )],
                                          ]}
                                        />
                                      ) : (
                                        <ProjectAuthorise permission={Permission.ManagePours}>
                                          <Actions
                                            right={[
                                              ['create', (
                                                <ConditionalButton
                                                  color="primary"
                                                  onClick={() => handleNewPour(recipe)}
                                                  disabled={stopeComplete || recipe.isStale}
                                                  disabledReason={stopeComplete ? 'Stope is complete' : 'This recipe is stale and has been disabled'}
                                                >
                                                  Create Pour
                                                </ConditionalButton>
                                              )],
                                            ]}
                                          />
                                        </ProjectAuthorise>
                                      )}
                                    </>
                                  )}
                                />
                              </Box>
                            </>
                          )
                      }
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )
              : (
                <RecipeErroredPlaceHolder recipe={recipe} message="The rheology data set for this recipe has been deleted" onDelete={() => handleDelete(recipe)} />
              )
          }
        </React.Fragment>
      ))}
    </div>
  );
};

export default RecipesTabPanel;
