import { DEFAULT_SELECTED_COLOUR } from './Diagra/DConstants';

// COLOURS
export const GREY = '#ddd';
export const LIGHT_GREY = '#aaa';
export const WARM_GREY = '#eba';
export const DARK_WARM_GREY = '#766';
export const COOL_GREY = '#abb';
export const DARK_COOL_GREY = '#667';
export const WHITE = '#fff';
export const BLACK = '#121212';
export const RED = '#e36';
export const DARK_RED = '#d13';
export const GREEN = '#2F3';
export const YELLOW = '#fd0';
export const PURPLE = '#f0f';
export const ORANGE = '#f60';
export const PINK = '#d08';
export const LIGHT_RED = '#f66';
export const BLUE = '#22f';
export const CYAN = '#0ff';
export const LIGHT_GREEN = '#9f9';
export const LIGHT_BLUE = '#99f';
export const DARK_GREY = '#777';
export const PC_BLUE = '#459cd5';

export const HGL_BACKGROUND_COLOR = BLACK;
export const BACKGROUND_COLOR = '#303030';
export const HOVER_COLOR = GREY;
export const SELECT_COLOR = YELLOW;
export const TAB_COLOR = WHITE;

export const TEXT_COLOR = GREY;
export const RANGE_COLOR = GREY;
export const BAR_COLOR = '#f59586';
export const SCALE_COLOR = GREY;
export const BAR_HOVER = WHITE;
export const SLIDER_COLOR = DARK_GREY;

export const SOFT_YELLOW = '#F6EB61';
export const PEACH_ORANGE = '#FF9A00';
export const LIGHT_PINK = '#FFB3D1';
export const CORAL = '#FF6F61';
export const SKY_BLUE = '#87CEEB';
export const DEEP_SKY_BLUE = '#00BFFF';
export const MINT_GREEN = '#98FF98';
export const PASTEL_BLUE = '#AEC6CF';
export const DARK_BLUE = '#003366';
export const LIGHT_GRAY = '#D3D3D3';
export const DARK_GRAY = '#333333';

export const PIPE_RED = '#e6194B';
export const PIPE_GREEN = '#3cb44b';
export const PIPE_YELLOW = '#ffe119';
export const PIPE_BLUE = '#4363d8';
export const PIPE_ORANGE = '#f58231';
export const PIPE_PURPLE = '#911eb4';
export const PIPE_CYAN = '#42d4f4';
export const PIPE_MAGENTA = '#f032e6';
export const PIPE_LIME = '#bfef45';
export const PIPE_PINK = '#fabed4';
export const PIPE_TEAL = '#469990';
export const PIPE_LAVENDER = '#dcbeff';
export const PIPE_BROWN = '#9A6324';
export const PIPE_BEIGE = '#fffac8';
export const PIPE_MAROON = '#800000';
export const PIPE_MINT = '#aaffc3';
export const PIPE_OLIVE = '#808000';
export const PIPE_APRICOT = '#ffd8b1';
export const PIPE_NAVY = '#000075';
export const PIPE_GREY = '#a9a9a9';
export const PIPE_WHITE = '#ffffff';
export const PIPE_BLACK = '#000000';

export const PIPE_COLOURS = [
  PIPE_RED,
  PIPE_GREEN,
  PIPE_YELLOW,
  PIPE_BLUE,
  PIPE_ORANGE,
  PIPE_PURPLE,
  PIPE_CYAN,
  PIPE_MAGENTA,
  PIPE_LIME,
  PIPE_PINK,
  PIPE_TEAL,
  PIPE_LAVENDER,
  PIPE_BROWN,
  PIPE_BEIGE,
  PIPE_MAROON,
  PIPE_MINT,
  PIPE_OLIVE,
  PIPE_APRICOT,
  PIPE_NAVY,
  PIPE_GREY,
  PIPE_WHITE,
  PIPE_BLACK,
];

export const COLOR_KEY = {
  Default: DEFAULT_SELECTED_COLOUR,
  'No Warnings': GREEN,
  'Slack Flow': PURPLE,
  'Over Pressure': RED,
};

// DEFAULT VALUES FOR VARIABLES
export const FLOW_RATE: number = 125;
export const TONNAGE: number = 113;
export const YIELD_STRESS: number = 100;
export const VISCOSITY: number = 0.05;
export const DENSITY: number = 1.85;
export const SG: number = 2.77;
export const CONCENTRATION: number = 0.78;
export const CEMENT: number = 2;
export const CEMENT2: number = 3;
export const PUMP: number = 0;

// GUI/GRAPHICS SETUP
// export const GUI_SCALE: number = 0.85;
export const TEXT_VERT_OFFSET: number = 20;
export const MARGIN: number = 0;
export const NODE_RADIUS: number = 2;
export const STOPE_RADIUS: number = 5;

// Diagra
export const DEFAULT_POINT_COLOUR = WHITE;
export const POINT_DIAMETER = 2;

export const LRect = {
  x: 20,
  y: 1000,
  width: 150,
  height: 165,
};

export const ARect = {
  x: 150,
  y: 1000,
  width: 200,
  height: 50,
};
