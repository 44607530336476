import { UnitSystem, CustomUnitFields } from 'providers/api';
import ModelState from '../ModelState';
import GuiState from './GuiState';
import * as PCConstants from '../../DiagraExtended/PCConstants';

export default function setupGui(
  _modelCanvas: HTMLCanvasElement,
  drawerWidth: number,
  unitSystemPreference: UnitSystem,
  mainMenuWidth: number,
  modelState: ModelState,
  customUnitFields: CustomUnitFields[],
  ) {
  const modelCanvas = _modelCanvas;
  const bufferCanvas = <HTMLCanvasElement>document.createElement('canvas');
  const hglCanvas = <HTMLCanvasElement>document.createElement('canvas');
  const telemetryCanvas = <HTMLCanvasElement>document.createElement('canvas')!;
  const pipeColorCanvas = <HTMLCanvasElement>document.createElement('canvas')!;
  modelCanvas.width = window.innerWidth;
  modelCanvas.height = window.innerHeight;
  telemetryCanvas.width = PCConstants.TELEMETRY_CANVAS_WIDTH;

  return new GuiState(
  modelCanvas, bufferCanvas, hglCanvas, telemetryCanvas, pipeColorCanvas, drawerWidth,
  unitSystemPreference, mainMenuWidth, modelState, customUnitFields,
);
}
