import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { GridPageChangeParams } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import * as childConstants from 'modules/notifications-admin/NewNotificationPage/constants';
import { NotificationDto } from 'providers/api';
import { useDeleteNotificationMutation, useListAllNotificationsQuery } from 'providers/api/useNotifications';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import { Link } from 'react-router-dom';
import NotificationsTable from './NotificationsTable';

const NewNotificationButton = () => (
  <>
    <Hidden xsDown>
      <Button
        aria-label="add new project"
        variant="contained"
        color="primary"
        size="large"
        startIcon={<AddIcon />}
        component={Link}
        to={childConstants.ROUTE_PATH}
      >
        New Notification
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton
        aria-label="add new Notification"
        color="primary"
        component={Link}
        to={childConstants.ROUTE_PATH}
      >
        <AddIcon />
      </IconButton>
    </Hidden>
  </>
);

const NotificationPage = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const confirm = useConfirmation();

  const deleteNotificationMutation = useDeleteNotificationMutation();

  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = useListAllNotificationsQuery({ page, pageSize });

  const handlePageChange = (change: GridPageChangeParams) => {
    setPage(change.page + 1);
  };

  const handlePageSizeChange = (change: GridPageChangeParams) => {
    setPage(1);
    setPageSize(change.pageSize);
  };

  const handleDelete = (notification: NotificationDto) => {
    confirm({
      variant: 'danger',
      description: `Are you sure you want to remove the notification '${notification.title}' for all users?`,
    }).then(() => deleteNotificationMutation.mutate(notification));
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
        <NewNotificationButton />
      </Box>
      <Box mt={3} component={Paper}>
        <NotificationsTable
          data={data?.items}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onDelete={handleDelete}
        />
      </Box>
    </Container>
  );
};

export default NotificationPage;
