import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { throughputUnitLabelFromThroughputControlType } from 'hooks/useDisplayUnits/display';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import {
  PourDto,
  ThroughputControlType,
  UnitSystem,
  CustomUnitFields
} from 'providers/api';
import { round } from 'utils';
import UnitSystemDetail from './ProjectAreaPage/ProjectStopesPage/StopePanelPage/RecipesTab/UnitSystemDetail';

interface PourDetail {
  key: string;
  design?: string;
  selected: string;
}

interface PourPasteFillDetailsProps {
  pour: PourDto;
  unitSystemPreference: UnitSystem;
  throughputControlType: ThroughputControlType;
  customUnitFields: CustomUnitFields[];
}

const PourPasteFillDetails = ({
  pour,
  unitSystemPreference,
  throughputControlType,
  customUnitFields,
}: PourPasteFillDetailsProps) => {
  const rows: PourDetail[] = [
    {
      key: 'Concentration',
      design: `${round(pour.hydraulicOptimisedSpecification?.massConcentration ?? 0, 2)} %m`,
      selected: `${round(pour.hydraulicSelectedSpecification?.massConcentration ?? 0, 2)} %m`,
    },
    {
      key: 'Settling Velocity',
      selected: `${round(pour.hydraulicSelectedSpecification?.settlingVelocity ?? 0, 2)} `,
    },
    {
      key: 'Minimum Velocity',
      selected: `${round(pour.hydraulicSelectedSpecification?.minVelocity ?? 0, 2)}`,
    },
  ];

  return (
    <>
      <UnitSystemDetail
        selected={pour.volume}
        unitSystem={unitSystemPreference}
        unitInfo={unitLabels[BasicDisplayUnits.Volume]}
        decimalPlaces={2}
        customUnitFields={customUnitFields}
      />

      <UnitSystemDetail
        designed={pour.hydraulicSpecification?.throughput}
        selected={pour.hydraulicSpecification?.throughput}
        unitSystem={unitSystemPreference}
        unitInfo={throughputUnitLabelFromThroughputControlType(throughputControlType)}
        decimalPlaces={2}
        customUnitFields={customUnitFields}
      />

      <TableRow>
        <TableCell component="th" scope="row">Binder:Tailings</TableCell>
        <TableCell align="right" width="auto">{`1:${round(100 / ((pour.hydraulicSpecification?.binderTailingsRatio ?? 0) * 100), 0)}`}</TableCell>
        <TableCell align="right">{`1:${round(100 / ((pour.hydraulicSpecification?.binderTailingsRatio ?? 0) * 100), 0)}`}</TableCell>
      </TableRow>

      {rows.map((row) => (
        <TableRow key={row.key}>
          <TableCell component="th" scope="row">{row.key}</TableCell>
          <TableCell align="right">{row.design}</TableCell>
          <TableCell align="right">{row.selected}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default PourPasteFillDetails;
