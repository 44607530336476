import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { UnitBase } from 'hooks/useDisplayUnits/useDisplayUnits';
import { UnitSystem, CustomUnitFields } from 'providers/api';
import { round } from 'utils';
import { isCustomUnitField } from 'utils/unitConversions';

interface UnitSystemValueProps {
  value?: number;
  unitSystem: UnitSystem;
  unitInfo: UnitBase;
  decimalPlaces: number;
  customUnitFields: CustomUnitFields[];
}

const UnitSystemValue = ({ value, unitSystem, unitInfo, decimalPlaces, customUnitFields }: UnitSystemValueProps) => {
  const isCustom = isCustomUnitField(unitSystem, customUnitFields, unitInfo.name);
  return (
    <span>
      {
      value && isCustom
        ? round(unitInfo.imperial.conversion(value as number), decimalPlaces)
        : round(value as number, decimalPlaces)
    }
      {
      value && isCustom
        ? unitInfo.imperial.abbreviation
        : unitInfo.metric.abbreviation
    }
    </span>
);
};
interface UnitSystemDetailProps {
  designed?: number;
  selected?: number;
  unitSystem: UnitSystem;
  unitInfo: UnitBase;
  decimalPlaces: number;
  customUnitFields: CustomUnitFields[];
}

const UnitSystemDetail = ({ designed, selected, unitSystem, unitInfo, decimalPlaces, customUnitFields }: UnitSystemDetailProps) => (
  <TableRow key={unitInfo.name}>
    <TableCell component="th" scope="row">
      {unitInfo.name}
    </TableCell>
    <TableCell align="right">
      {
        designed && (
          <UnitSystemValue
            value={designed}
            unitInfo={unitInfo}
            unitSystem={unitSystem}
            decimalPlaces={decimalPlaces}
            customUnitFields={customUnitFields}
          />
        )
      }
    </TableCell>
    <TableCell align="right">
      <UnitSystemValue
        value={selected}
        unitInfo={unitInfo}
        unitSystem={unitSystem}
        decimalPlaces={decimalPlaces}
        customUnitFields={customUnitFields}
      />
    </TableCell>
  </TableRow>
);

export default UnitSystemDetail;
