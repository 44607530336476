import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AlertText from 'components/AlertText';
import { HOUR_IN_MILLISECONDS } from 'config';
import useManualQuery from 'hooks/useManualQuery';
import { BasePageProps } from 'modules/makePage';
import {
  FileResponse,
  PourStatus,
  RheologyDataDto,
  useAbandonPourMutation,
  useDownloadPourNote,
  useMineModelRheologyDataListQuery,
  useUnapprovePourNoteMutation,
  useVoidPourNoteMutation
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import { ProjectProvider } from '../providers/project';
import PourDetailsCard from './PourDetailsCard';
import PourDetailsStepper from './PourDetailsStepper/PourDetailsStepper';
import { PourDetailsQueries } from './usePourDetailsQueries';

const PourDetailsPage = ({ queries }: BasePageProps<PourDetailsQueries>) => {
  const [pourQuery, projectQuery, mineModelQuery] = queries;

  const pour = pourQuery.data;
  const project = projectQuery.data;
  const mineModel = mineModelQuery.data;
  const confirm = useConfirmation();
  const voidPourNoteMutation = useVoidPourNoteMutation(pour?.entityId ?? '');
  const abandonPourMutation = useAbandonPourMutation(pour?.entityId ?? '');
  const unapprovePourNoteMutation = useUnapprovePourNoteMutation(pour?.entityId ?? '');

  const [rheologyDataSummary, setRheologyDataSummary] = React.useState<RheologyDataDto>();
  useMineModelRheologyDataListQuery(
    {
      mineModelId: project?.entityId || '',
      rheologyDataSetIds: pour ? [pour.rheologyDataSetId] : [],
    },
    {
      enabled: !!pour && !!project,
      onSuccess: (results) => setRheologyDataSummary(results[0]),
    },
  );

  const mineModelRheologyData = useMineModelRheologyDataListQuery({ mineModelId: project?.entityId!, rheologyDataSetIds: [pour?.rheologyDataSetId!] }, {
    staleTime: HOUR_IN_MILLISECONDS,
    enabled: !!(project?.entityId && pour?.rheologyDataSetId),
  });

  const {
    fetch,
    queryResult: { data: pourNoteFile, isStale: pourNoteIsStale, isFetching: pourNoteDownloading },
  } = useManualQuery<FileResponse>({
    query: useDownloadPourNote,
    rootEnabled: !!pour?.entityId && !!pour?.pourNote?.file.entityId,
    params: {
      pourId: pour?.entityId ?? '',
      pourNoteId: pour?.pourNote?.entityId ?? '',
    },
  });

  const handleVoid = () => {
    if (pour && pour.pourNote) {
      confirm({
        variant: 'danger',
        description: 'Are you sure you want to void this pour note?',
      }).then(() => voidPourNoteMutation.mutate(
        { pourId: pour.entityId, pourNoteId: pour.pourNote!.entityId },
      ));
    }
  };

  const handleAbandon = () => {
    if (pour) {
      confirm({
        variant: 'danger',
        description: 'Are you sure you want to abandon this pour?',
      }).then(() => abandonPourMutation.mutate(pour.entityId));
    }
  };

  const handleUnapprove = () => {
    if (pour && pour.pourNote) {
      confirm({
        variant: 'danger',
        description: 'Are you sure you want to unapprove this pour note?',
      }).then(() => unapprovePourNoteMutation.mutate(pour.pourNote!.entityId));
    }
  };

  const firstYieldStressCoefficient = rheologyDataSummary?.yieldStressCoefficients;
  const collatedDisplayValues = {
    mixerCoefficients: rheologyDataSummary?.mixerCoefficients,
    specificGravity: rheologyDataSummary?.tailingsDrySolidsDensity,
    heightOfCylinder: rheologyDataSummary?.heightOfCylinder,
    yieldStressA: firstYieldStressCoefficient?.coefficient1,
    yieldStressB: firstYieldStressCoefficient?.coefficient2,
    tailingsSolidsDensity: rheologyDataSummary?.tailingsDrySolidsDensity,
    binderParticleDensity: rheologyDataSummary?.binderDryParticleDensity,
    carrierFluidDensity: rheologyDataSummary?.carrierFluidDensity,
    targetDaysCoefficients: rheologyDataSummary?.ucsCoefficients,
  };

  if (!pour || !project || !mineModel || !mineModelRheologyData.data) {
    return <AlertText severity="info">Loading required data</AlertText>;
  }

  return (
    <ProjectProvider project={project}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <PourDetailsCard
              pourNoteFile={pourNoteIsStale ? undefined : pourNoteFile}
              pourNoteDownloading={pourNoteDownloading}
              pour={pour}
              displayUnitPreferences={project.displayUnitPreferences ?? []}
              unitSystemPreference={project.unitSystemPreference}
              customUnitFields={project.customUnitFields}
              specificGravity={collatedDisplayValues.specificGravity}
              heightOfCylinder={collatedDisplayValues.heightOfCylinder}
              mixerCoefficients={collatedDisplayValues.mixerCoefficients}
              yieldStressA={collatedDisplayValues.yieldStressA}
              yieldStressB={collatedDisplayValues.yieldStressB}
              tailingsSolidsDensity={collatedDisplayValues.tailingsSolidsDensity}
              binderParticleDensity={collatedDisplayValues.binderParticleDensity}
              carrierFluidDensity={collatedDisplayValues.carrierFluidDensity}
              onVoidPourNote={handleVoid}
              onAbandonPour={handleAbandon}
              onUnapprovePourNote={handleUnapprove}
              onAbandonIsLoading={abandonPourMutation.isLoading}
              unApprovedIsLoading={unapprovePourNoteMutation.isLoading}
              voidIsLoading={voidPourNoteMutation.isLoading}
              onDownloadPourNote={fetch}
              fillType={project.fillType}
              rheologyDataMissing={!!rheologyDataSummary}
              throughputControlType={project.throughput}
              targetDaysCoefficients={collatedDisplayValues.targetDaysCoefficients ?? {}}
            />

          </Grid>
          <Grid item xs={12} md={8}>
            {pour.status !== PourStatus.Abandoned
            && (
            <PourDetailsStepper
              pour={pour}
              unitSystemPreference={project.unitSystemPreference}
              customUnitFields={project.customUnitFields}
              fillType={project.fillType}
            />
          )}
          </Grid>
        </Grid>
      </Container>
    </ProjectProvider>

  );
};
export default PourDetailsPage;
