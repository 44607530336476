import { createContext } from 'react';
import {
  ApiService,
  BackfillRecipesService,
  ClientsService,
  InvitationsService,
  MineModelsService,
  NotificationsService,
  PoursService,
  PourTypesService,
  ProjectsService,
  StopeBackfillingService,
  TeamRolesService,
  TeamsService,
  UserNotificationsService,
  UsersService
} from './api';

export interface IApiContext {
  clients: ClientsService,
  projects: ProjectsService,
  users: UsersService,
  teams: TeamsService,
  invitations: InvitationsService,
  mineModels: MineModelsService,
  recipes: BackfillRecipesService,
  pours: PoursService,
  teamRoles: TeamRolesService,
  stopes: StopeBackfillingService,
  pourTypes: PourTypesService,
  contentApi: ApiService,
  userNotifications: UserNotificationsService,
  notifications: NotificationsService,
}

const ApiContext = createContext<IApiContext | null>(null);

export default ApiContext;
