import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { UserNotificationDto } from 'providers/api';
import { DEFAULT_SHORT_DATE_CONFIG, displayTemporal } from 'utils';

interface NotificationBoxProps {
  notification: UserNotificationDto;
}

const NotificationBox = ({ notification }: NotificationBoxProps) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    padding="8px"
    marginBottom="8px"
    bgcolor="#5d5d5d"
    borderRadius="8px"
    position="relative"
    width="100%"
    boxSizing="border-box"
  >
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box style={{ flexGrow: 1 }}>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          {notification.notification.title}
        </Typography>
        <div
          className="notificationStyling"
          // NOTE: CSS for this html can be found here: /src/Modules/BackfillPlanner.UI/ClientApp/src/index.css
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
          __html: notification.notification.body,
          }}
        />
      </Box>
    </Box>
    <Box
      marginTop="8px"
      color="#d3d3d3"
      fontSize="0.8em"
    >
      {displayTemporal(notification.createdDate, DEFAULT_SHORT_DATE_CONFIG)}
    </Box>
  </Box>
  );

export default NotificationBox;
