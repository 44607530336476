import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useForm from 'hooks/useForm';
import { NotificationForm as ServerNotificationForm } from 'providers/api';
import { UseMutationResult } from 'react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { object, string } from 'yup';

const NotificationSchema = object().shape({
  title: string().required('Title is required'),
  body: string().required('Body is required'),
});

interface NotificationFormProps {
  mutation: UseMutationResult<void, unknown, ServerNotificationForm, unknown>;
}

const NotificationForm = ({ mutation }: NotificationFormProps) => {
  const { formik, helpers } = useForm<{ title: string; body: string }>({
    initialPath: '',
    mutation,
    formikConfig: {
      initialValues: {
        title: '',
        body: '',
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(form, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: NotificationSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6">Notification Details</Typography>
      <Box component={Paper} p={2} mb={2}>
        <TextField
          required
          fullWidth
          id="title"
          name="title"
          label="New Notification Title"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={helpers.hasError('title')}
          helperText={helpers.getErrorHelpText('title')}
          autoComplete="off"
        />
      </Box>

      <Box component={Paper} p={2} mb={2}>
        <ReactQuill
          className="custom-quill"
          theme="snow"
          value={values.body}
          onChange={(content) => setFieldValue('body', content)}
          onBlur={() => handleBlur({ target: { name: 'body' } })}
          style={{
            zIndex: 2000,
            position: 'relative',
          }}
        />
        {helpers.hasError('body') && (
          <Typography color="error">
            {helpers.getErrorHelpText('body')}
          </Typography>
        )}
      </Box>

      <Box mt={2}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
        >
          Send Notification
        </Button>
      </Box>
    </form>
  );
};

export default NotificationForm;
