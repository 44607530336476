import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FormikActions } from 'components/Form';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import useForm from 'hooks/useForm';
import UnitSystemNumberField from 'modules/project/ProjectAreaPage/ProjectStopesPage/StopePanelPage/RecipesTab/RecipeForm/UnitSystemNumberField';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  FillType,
  UnitSystem,
  UpdatePourVolumeCommand,
  CustomUnitFields
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import { UseMutationResult } from 'react-query';
import { number, object, SchemaOf } from 'yup';

interface UpdatePourVolumeFormProps {
  volume: number;
  volumeRetainedAfterOutflow?: number;
  unitSystemPreference: UnitSystem,
  fillType: FillType;
  mutation: UseMutationResult<void, unknown, UpdatePourVolumeCommand, unknown>;
  customUnitFields: CustomUnitFields[];
}

const UpdatePourVolumeForm = ({ volume, volumeRetainedAfterOutflow, unitSystemPreference, fillType, mutation, customUnitFields }: UpdatePourVolumeFormProps) => {
  const updatePourDateSchema: SchemaOf<UpdatePourVolumeCommand> = object().shape({
    volume: number()
      .moreThan(0)
      .required(),
    volumeRetainedAfterOutflow: number()
      .test(
        'is-required',
        () => 'volume retained is required when the fill type is set to hydraulic',
        (value) => (fillType === FillType.Hydraulic && value !== undefined) || fillType === FillType.Paste,
      ),
  });

  const {
    formik,
    helpers,
  } = useForm<UpdatePourVolumeCommand>({
    mutation,
    formikConfig: {
      initialValues: {
        volume,
        volumeRetainedAfterOutflow,
      },
      onSubmit: (updatePourVolumeForm, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        mutation.mutate(updatePourVolumeForm, {
          onSuccess: () => {
            resetForm({
              values: updatePourVolumeForm,
            });
          },
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: updatePourDateSchema,
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  return (
    <ProjectAuthorise
      permission={Permission.ManagePours}
      render={(isAuthorised) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="subtitle1">Update Pour Volume</Typography>
          <Box component={Paper} p={2} mb={2}>
            <UnitSystemNumberField
              unitSystem={unitSystemPreference}
              customUnitFields={customUnitFields}
              unitInfo={unitLabels[BasicDisplayUnits.Volume]}
              decimalPlaces={2}
              type="number"
              disabled={!isAuthorised}
              error={helpers.hasError('volume')}
              helperText={helpers.getErrorHelpText('volume')}
              required
              fullWidth
              id="volume"
              name="volume"
              label="Volume"
              value={values.volume}
              onChange={(e) => setFieldValue('volume', e.target.value)}
              onBlur={handleBlur}
            />

            {fillType === FillType.Hydraulic && (
              <>
                <Box mt={2}>
                  <TextField
                    label="% Volume Retained After Outflow"
                    value={values.volumeRetainedAfterOutflow}
                    name="volumeRetainedAfterOutflow"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    fullWidth
                    variant="outlined"
                  />
                </Box>
                <Box mt={2}>
                  <UnitSystemNumberField
                    unitSystem={unitSystemPreference}
                    customUnitFields={customUnitFields}
                    unitInfo={unitLabels[BasicDisplayUnits.Volume]}
                    decimalPlaces={2}
                    type="number"
                    fullWidth
                    disabled
                    label="Final Fill Volume"
                    value={
                      values.volumeRetainedAfterOutflow
                        ? values.volume * (values.volumeRetainedAfterOutflow / 100)
                        : values.volume
                    }
                    InputLabelProps={{
                      shrink: Boolean(values.volumeRetainedAfterOutflow || values.volume),
                    }}
                  />
                </Box>
              </>
            )}
          </Box>

          {isAuthorised && (
            <Box mt={2}>
              <FormikActions
                formik={formik}
                mutation={mutation}
                submitText="Update"
                right={['reset', 'submit']}
              />
            </Box>
          )}
        </form>
      )}
    />
  );
};

export default UpdatePourVolumeForm;
