import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useDisplayUnits from 'hooks/useDisplayUnits';
import {
  DisplayUnits,
  FillType,
  MixerCoefficientsDto,
  PourDto,
  ThroughputControlType,
  UnitSystem,
  UcsCoefficientsDto,
  CustomUnitFields
} from 'providers/api';
import PourHydraulicFillDetails from './PourHydraulicFillDetails';
import PourPasteFillDetails from './PourPasteFillDetails';
import UnitSystemDetail from './ProjectAreaPage/ProjectStopesPage/StopePanelPage/RecipesTab/UnitSystemDetail';
import WarningTooltipIcon from './ProjectAreaPage/ProjectStopesPage/StopePanelPage/WarningTooltipIcon';

const useStyles = makeStyles(() => createStyles({
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: '0.9em',
      padding: 10,
      margin: 0,
      paddingBottom: 10,
    },
  },
}));

interface PourCardProps {
  pour: PourDto;
  displayUnitPreferences: DisplayUnits[];
  unitSystemPreference: UnitSystem;
  specificGravity?: number;
  yieldStressA?: number;
  yieldStressB?: number;
  mixerCoefficients?: MixerCoefficientsDto;
  heightOfCylinder?: number;
  fillType: FillType;
  throughputControlType: ThroughputControlType;
  tailingsSolidsDensity?: number;
  binderParticleDensity?: number;
  carrierFluidDensity?: number;
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; }
  customUnitFields : CustomUnitFields[];
}

const PourDetails = ({
  pour,
  displayUnitPreferences,
  unitSystemPreference,
  specificGravity,
  yieldStressA,
  yieldStressB,
  mixerCoefficients,
  heightOfCylinder,
  fillType,
  throughputControlType,
  tailingsSolidsDensity,
  binderParticleDensity,
  carrierFluidDensity,
  targetDaysCoefficients,
  customUnitFields,
}: PourCardProps) => {
  const classes = useStyles();

  const displayUnitResults = useDisplayUnits([{
    key: 'adjusted',
    displayUnitPreferences,
    specification: fillType === FillType.Paste ? pour.pasteSpecification : pour.hydraulicSpecification,
    selectedSpecification: fillType === FillType.Paste ? pour.pasteSelectedSpecification : pour.hydraulicSelectedSpecification,
    specificGravity,
    yieldStressA,
    yieldStressB,
    mixerCoefficients,
    heightOfCylinder,
    fillType,
    throughputControlType,
    tailingsSolidsDensity,
    binderParticleDensity,
    carrierFluidDensity,

  },
  {
    key: 'optimised',
    displayUnitPreferences,
    specification: fillType === FillType.Paste ? pour.pasteSpecification : pour.hydraulicSpecification,
    selectedSpecification: fillType === FillType.Paste ? pour.pasteOptimisedSpecification : pour.hydraulicOptimisedSpecification,
    specificGravity,
    yieldStressA,
    yieldStressB,
    mixerCoefficients,
    heightOfCylinder,
    fillType,
    throughputControlType,
    tailingsSolidsDensity,
    binderParticleDensity,
    carrierFluidDensity,
  }]);

  const { additionalDisplayUnits: additionalAdjustedDisplayUnitMap, warnings } = displayUnitResults.adjusted;
  const { additionalDisplayUnits: additionalOptimisedDisplayUnitMap } = displayUnitResults.optimised;

  const processedWarnings = Object.entries(warnings).filter(([/* key */, value]) => value !== undefined).map(([/* key */, value]) => ({ display: value! }));

  return (
    <>
      {processedWarnings && processedWarnings.length > 0 && (
        <Box width="100%" display="flex" flexDirection="row-reverse" mb={1}>
          <WarningTooltipIcon warnings={processedWarnings as any} />
        </Box>
      )}
      <Box width="100%" display="flex" pl={2}>
        <Typography variant="caption">Rheology Data: </Typography>
      </Box>
      <Box width="100%" display="flex" mb={1} pl={2}>
        <Typography variant="caption">{`${pour.rheologyDataSetReference} - ${pour.rheologyDataSetVersion}`}</Typography>
      </Box>
      <Table className={classes.table} aria-label="Pour details table" size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">Design</TableCell>
            <TableCell align="right">Selected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            fillType === FillType.Paste
              ? (
                <PourPasteFillDetails
                  pour={pour}
                  unitSystemPreference={unitSystemPreference}
                  throughputControlType={throughputControlType}
                  targetDaysCoefficients={targetDaysCoefficients}
                  customUnitFields={customUnitFields}
                />
                )
              : (
                <PourHydraulicFillDetails
                  pour={pour}
                  unitSystemPreference={unitSystemPreference}
                  throughputControlType={throughputControlType}
                  customUnitFields={customUnitFields}
                />
                )
          }
          <TableRow>
            <TableCell colSpan={3}><Divider /></TableCell>
          </TableRow>
          {
            Object.entries(additionalAdjustedDisplayUnitMap)
              .filter(([/* key */, unitInfo]) => unitInfo.value !== undefined)
              .map(([key, unitInfo]) => (
                <UnitSystemDetail
                  designed={(additionalOptimisedDisplayUnitMap as any)[key].value}
                  selected={unitInfo.value}
                  unitSystem={unitSystemPreference}
                  unitInfo={unitInfo}
                  decimalPlaces={unitInfo.decimalPlaces}
                  customUnitFields={customUnitFields}
                />
              ))
          }
        </TableBody>
      </Table>
    </>
  );
};

export default PourDetails;
