import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { GridOptions } from '@material-ui/data-grid/';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  PAGINATION_PAGE_SIZES
} from 'config';
import { NotificationDto } from 'providers/api';
import React from 'react';
import { DEFAULT_SHORT_DATE_CONFIG, displayTemporal } from 'utils';

const useStyles = makeStyles({
  root: {
    '& .auto-height-row': {
      height: 'auto',
      maxHeight: '150px',
      overflowY: 'auto',
    },
  },
});

interface NotificationActionsMenuProps {
  notification: NotificationDto;
  onDelete: (notification: NotificationDto) => void;
}
const NotificationActionsMenu = ({ notification, onDelete }: NotificationActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(notification);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls={`notification-actions-menu-${notification.entityId}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`notification-actions-menu-${notification.entityId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          <Typography color="error" variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

interface NotificationsTableProps {
  data: NotificationDto[] | undefined;
  page: number;
  pageSize: number;
  totalItems: number | undefined;
  onPageChange: GridOptions['onPageChange'];
  onPageSizeChange: GridOptions['onPageSizeChange'];
  onDelete: (notification: NotificationDto) => void;
  loading: boolean;
  loadingNew: boolean;
}

const NotificationsTable = ({
  data,
  onPageChange,
  onPageSizeChange,
  onDelete,
  loadingNew,
  loading,
  page,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
  totalItems = 0,
}: NotificationsTableProps) => {
  const classes = useStyles();
  const pageIndex = data ? (page - 1) : 0;
  const rows = data ?? [];

  const columns: ResponsiveGridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.5,
      resizable: false,
      sortable: false,
      filterable: false,
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
    },
    {
      field: 'CreatedDateUtc',
      headerName: 'Sent Date',
      width: 150,
      resizable: false,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        const notification = row as NotificationDto;
        return displayTemporal(notification.createdDate, DEFAULT_SHORT_DATE_CONFIG);
      },
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
    },
    {
      headerName: 'Content',
      field: 'content',
      sortable: false,
      filterable: false,
      resizable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Box
          className="notificationStyling"
          // NOTE: CSS for this html can be found here: /src/Modules/BackfillPlanner.UI/ClientApp/src/index.css
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
          __html: (row as NotificationDto).body,
          }}
        />
      ),
    },
    {
      field: '',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 80,
      renderCell: ({ row }) => (
        <NotificationActionsMenu
          notification={(row as any) as NotificationDto}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <DataGridWithLoading
      className={classes.root}
      getRowClassName={() => 'auto-height-row'}
      getCellClassName={() => 'auto-height-row'}
      density="comfortable"
      loading={loading}
      loadingNew={loadingNew}
      searchTerm=""
      searchFields={['name']}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      idProp="entityId"
      rows={rows as NotificationDto[]}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowCount={totalItems}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
    />
  );
};

export default NotificationsTable;
