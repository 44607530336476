import makePage from 'modules/makePage';
import Page from 'modules/Page';
import * as constants from './constants';
import NotificationsPage from './NotificationsPage';

const page: Page = {
  title: constants.TITLE,

  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  component: makePage({ WrappedPage: NotificationsPage, titleFn: () => constants.TITLE }),
};

export default page;
