import { BasicDisplayUnits, unitLabels, additionalUnitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import { DisplayUnits, ThroughputControlType } from 'providers/api';

 export const ThroughputControlTypeLabel: Record<ThroughputControlType, string> = {
     [ThroughputControlType.DryTonnage]: 'Dry Tonnage',
     [ThroughputControlType.WetTonnage]: 'Wet Tonange',
     [ThroughputControlType.FlowRate]: 'Flow Rate',
 };

export const ThroughputControlTypeImperialAbbrev: Record<ThroughputControlType, string> = {
    [ThroughputControlType.DryTonnage]: unitLabels[BasicDisplayUnits.DryTonnage].imperial.abbreviation,
    [ThroughputControlType.WetTonnage]: unitLabels[BasicDisplayUnits.WetTonnage].imperial.abbreviation,
    [ThroughputControlType.FlowRate]: additionalUnitLabels[DisplayUnits.FlowRate].imperial.abbreviation,
};

export const ThroughputControlTypeMetricAbbrev: Record<ThroughputControlType, string> = {
    [ThroughputControlType.DryTonnage]: unitLabels[BasicDisplayUnits.DryTonnage].metric.abbreviation,
    [ThroughputControlType.WetTonnage]: unitLabels[BasicDisplayUnits.WetTonnage].metric.abbreviation,
    [ThroughputControlType.FlowRate]: additionalUnitLabels[DisplayUnits.FlowRate].metric.abbreviation,
};
