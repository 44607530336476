import NewPourPage from './NewPourPage';
import PourDetailsPage from './PourDetailsPage';
import ProjectAreaPage from './ProjectAreaPage';
import ProjectsPage from './ProjectsPage';
import NewRheologyPage from './ProjectAreaPage/ProjectDetailsPage/NewRheologyPage';
import EditProjectPage from './ProjectAreaPage/ProjectDetailsPage/EditRheologyPage';

const projectModule = {
  pages: [
    NewPourPage,
    PourDetailsPage,
    ProjectsPage,
    ProjectAreaPage,
    NewRheologyPage,
    EditProjectPage,
  ],
};

export default projectModule;
