import Container from '@material-ui/core/Container';
import { useCreateGlobalNotificationMutation } from 'providers/api/useNotifications';
import { useHistory } from 'react-router-dom';
import NotificationForm from '../NotificationForm';
import { NOTIFICATIONS_ROUTE_PATH } from './constants';

const NewNotificationPage = () => {
  const history = useHistory();

  const mutation = useCreateGlobalNotificationMutation({
    onSuccess: () => {
      history.push(NOTIFICATIONS_ROUTE_PATH);
    },
  });

  return (
    <Container maxWidth="lg">
      <NotificationForm mutation={mutation} />
    </Container>
  );
};

export default NewNotificationPage;
