import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { GridPageChangeParams } from '@material-ui/data-grid';
import { PAGINATION_DEFAULT_PAGE_SIZE, PAGINATION_PAGE_SIZES } from 'config';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import { BasePageProps } from 'modules/makePage';
import {
  ProjectPoursQueryParams,
  StopeDataDto,
  UnitSystem,
  useMineModelRheologyDataListQuery,
  useProjectPoursQuery
} from 'providers/api';
import { indexBy, prop } from 'ramda';
import React from 'react';
import { useParams } from 'react-router';
import { round } from 'utils';
import { ProjectAreaPageParams } from '../constants';
import { ProjectAreaQueries } from '../useProjectAreaQueries';
import PourTableSearchForm from './PourTableSearchForm';
import PoursTable from './PoursTable';

const ProjectReportsPage = ({ queries }: BasePageProps<ProjectAreaQueries>) => {
  const [projectQuery] = queries;
  const project = projectQuery.data;

  const params = useParams<ProjectAreaPageParams>();

  const [searchParams, setSearchParams] = React.useState<ProjectPoursQueryParams>({
    projectId: params.entityId,
    page: 1,
    pageSize: PAGINATION_DEFAULT_PAGE_SIZE,
  });

  const { data: pours, isFetched, isFetching, isPreviousData } = useProjectPoursQuery(searchParams);

  const { isLoading: rheologyDataSummaryListIsLoading, data: rheologyDataSummaries } = useMineModelRheologyDataListQuery(
    {
      mineModelId: project?.entityId ?? '',
      rheologyDataSetIds: pours ? pours.items.map((pour) => pour.rheologyDataSetId ?? '') : [],
    },
    {
      enabled: !!project && !!pours && !!pours?.items && pours.items.length > 0,
    },
  );

  const rheologyDataSummariesIndexedById = rheologyDataSummaries ? indexBy(prop('rheologyDataSetId'), rheologyDataSummaries) : {};

  const totalPours = pours?.pager.totalItems;

  const tableData = pours && pours.items
    ? pours.items.map((pour) => ({
      ...pour,
      volume: project?.unitSystemPreference === UnitSystem.Metric
        ? pour.volume
        : round(unitLabels[BasicDisplayUnits.Volume].imperial.conversion(pour.volume), 2),
    }))
    : [];

    const [filteredStopes, setFilteredStopes] = React.useState<StopeDataDto[]>([]);

    React.useEffect(() => {
      if (pours && pours.items.length > 0) {
        const newStopes = pours.items.flatMap((pour) => pour.stopeData).filter(Boolean); // Remove undefined values
        setFilteredStopes((prev) => {
          const mergedStopes = [...prev, ...newStopes]
            .filter((value, index, array) => index === array.findIndex((item) => item?.stopeId === value?.stopeId));
          return mergedStopes as StopeDataDto[];
        });
      }
    }, [pours]); // adds stope data to list as table paginated

  const handleSearch = (newSearchParams: ProjectPoursQueryParams) => setSearchParams(newSearchParams);
  const handlePageSizeChange = (change: GridPageChangeParams) => setSearchParams({ ...searchParams, page: 1, pageSize: change.pageSize });
  const handlePageChange = (change: GridPageChangeParams) => setSearchParams({ ...searchParams, page: change.page + 1 });

  return (
    <Container maxWidth="lg">
      <Typography variant="subtitle1">Pours Overview</Typography>
      <Box mb={2}>
        <PourTableSearchForm searchParams={searchParams} onSearch={handleSearch} stopes={filteredStopes} />
      </Box>
      <Box mt={3} component={Paper}>
        {!rheologyDataSummaryListIsLoading && !!project && !!pours && (
          <PoursTable
            projectName={project.name}
            pours={tableData ?? []}
            rheologyDataIndexedById={rheologyDataSummariesIndexedById}
            totalPours={totalPours ?? 0}
            page={searchParams.page ?? 1}
            pageSize={searchParams.pageSize ?? PAGINATION_DEFAULT_PAGE_SIZE}
            pageSizeOptions={[...PAGINATION_PAGE_SIZES, 100]}
            loading={isFetching}
            loadingNew={isFetching && (!isFetched || isPreviousData)}
            displayUnitPreferences={project.displayUnitPreferences}
            unitSystemPreference={project.unitSystemPreference}
            customUnitFields={project.customUnitFields}
            fillType={project.fillType}
            throughputControlType={project.throughput}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        )}
      </Box>
    </Container>
  );
};

export default ProjectReportsPage;
