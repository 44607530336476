import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { GridPageChangeParams } from '@material-ui/data-grid';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import { useMyUserNotificationsQuery } from 'providers/api/useNotifications';
import React from 'react';
import NotificationsTable from './NotificationsTable';

const NotificationPage = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);

  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = useMyUserNotificationsQuery({ page, pageSize, includeRead: true });

  const handlePageChange = (change: GridPageChangeParams) => {
    setPage(change.page + 1);
  };

  const handlePageSizeChange = (change: GridPageChangeParams) => {
    setPage(1);
    setPageSize(change.pageSize);
  };

  return (
    <Container maxWidth="lg">
      <Box mt={3} component={Paper}>
        <NotificationsTable
          data={data?.items ?? []}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
    </Container>
  );
};

export default NotificationPage;
