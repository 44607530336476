import { PourStatus } from 'providers/api/api';

const PourStatusDisplay: Record<PourStatus, string> = {
    [PourStatus.Planned]: 'Planned',
    [PourStatus.PourNoteGenerated]: 'Generated',
    [PourStatus.AwaitingApproval]: 'Awaiting Approval',
    [PourStatus.Approved]: 'Approved',
    [PourStatus.Complete]: 'Complete',
    [PourStatus.Abandoned]: 'Abandoned',
};

export default PourStatusDisplay;
