import TimelineIcon from '@material-ui/icons/Timeline';
import makePage from 'modules/makePage';
import Page from 'modules/Page';
import * as constants from './constants';
import NotificationsPage from './NotificationsPage';

const page: Page = {
  title: constants.TITLE,
  mainMenuConfig: {
    group: 'Admin',
    iconComponent: TimelineIcon,
  },
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage({ WrappedPage: NotificationsPage, titleFn: () => constants.TITLE }),
};

export default page;
