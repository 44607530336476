import { makeStyles } from '@material-ui/core/styles';
import { GridOptions } from '@material-ui/data-grid/';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  PAGINATION_PAGE_SIZES
} from 'config';
import { UserNotificationDto } from 'providers/api';
import { DEFAULT_SHORT_DATE_CONFIG, displayTemporal } from 'utils';

const useStyles = makeStyles({
  root: {
    '& .auto-height-row': {
      height: 'auto',
      maxHeight: '150px',
      overflowY: 'auto',
    },
  },
});
interface NotificationsTableProps {
  data: UserNotificationDto[] | undefined;
  page: number;
  pageSize: number;
  totalItems: number | undefined;
  onPageChange: GridOptions['onPageChange'];
  onPageSizeChange: GridOptions['onPageSizeChange'];
  loading: boolean;
  loadingNew: boolean;
  searchTerm?: string | null;
}

const NotificationsTable = ({
  data,
  onPageChange,
  onPageSizeChange,
  loadingNew,
  loading,
  page,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
  totalItems = 0,
  searchTerm,
}: NotificationsTableProps) => {
  const classes = useStyles();
  const pageIndex = data ? (page - 1) : 0;
  const rows = data ?? [];

  const columns: ResponsiveGridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      resizable: false,
      width: 200,
      sortable: false,
      filterable: false,
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
      renderCell: ({ row }) => (
        <div>{ (row as UserNotificationDto).notification.title }</div>
      ),
    },
    {
      field: 'CreatedDateUtc',
      headerName: 'Sent Date',
      width: 150,
      resizable: false,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        const notification = row as UserNotificationDto;
        return displayTemporal(notification.createdDate, DEFAULT_SHORT_DATE_CONFIG);
      },
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
    },
    {
      headerName: 'Content',
      field: 'content',
      sortable: false,
      filterable: false,
      resizable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <div
          className="notificationStyling"
          // NOTE: CSS for this html can be found here: /src/Modules/BackfillPlanner.UI/ClientApp/src/index.css
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
          __html: (row as UserNotificationDto).notification.body,
          }}
        />
      ),
    },
  ];

  return (
    <DataGridWithLoading
      className={classes.root}
      getRowClassName={() => 'auto-height-row'}
      getCellClassName={() => 'auto-height-row'}
      loading={loading}
      loadingNew={loadingNew}
      searchTerm={searchTerm ?? null}
      searchFields={['name']}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      idProp="entityId"
      rows={rows as UserNotificationDto[]}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowCount={totalItems}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
    />
  );
};

export default NotificationsTable;
