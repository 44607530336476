import Page from 'modules/Page';
import makePage from 'modules/makePage';
import useNewRheologyDataQueries, { NewRheologyDataQueries } from '../../../../shared/NewRheologyPage/useNewRheologyDataQueries';
import NewRheologyPage from './NewRheologyPage';
import * as parentConstants from '../../constants';
import * as constants from './constants';
import { NewRheologyPageParams } from './constants';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: false,
  component: makePage<NewRheologyPageParams, NewRheologyDataQueries>({
    WrappedPage: NewRheologyPage,
    titleFn: () => 'New Rheology Data',
    parentPathTemplate: parentConstants.DETAILS_ROUTE_PATH,
    parentTitleFn: () => parentConstants.DETAILS_TITLE,
    pageDataHook: useNewRheologyDataQueries,
  }),
};

export default page;
