import Layout, { TopLevelMenuItem } from 'components/Layout';
import Loading from 'components/Loading';
import { HOUR_IN_MILLISECONDS } from 'config';
import pages from 'modules';
import { pathMatchesWithPathTemplate } from 'modules/makePage';
import Page from 'modules/Page';
import * as projectsPageConstants from 'modules/project/ProjectsPage/constants';
import { useSnackbar } from 'notistack';
import { useProjectClientQuery } from 'providers/api';
import { useAuthorisation } from 'providers/authorisation';
import { IAuthorisationContext } from 'providers/authorisation/context';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';
import {
  CSSTransition,
  SwitchTransition
} from 'react-transition-group';
import EnvironmentBanner from './components/EnvironmentBanner';

const DEFAULT_ROUTE = projectsPageConstants.ROUTE_PATH;

interface RedirectWithErrorProps {
  error: string,
  to: string,
}
const RedirectWithError = ({ error, to }: RedirectWithErrorProps) => {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    enqueueSnackbar(error, { variant: 'error' });
  }, []);

  return <Redirect to={to} />;
};

interface AuthorisePageProps {
  page: Page<any>,
  params: any,
  authorisationContext: IAuthorisationContext
}

const AuthorisePage = ({ page, params, authorisationContext }: AuthorisePageProps) => {
  const authoriseConfig = page.authorise ? page.authorise(authorisationContext, params as any) : undefined;
  const { data, isFetching, error } = useProjectClientQuery(authoriseConfig?.teamId ?? '', {
    enabled: !!authoriseConfig?.teamId,
    staleTime: 24 * HOUR_IN_MILLISECONDS,
  });

  if (authorisationContext.state.loading || (!!authoriseConfig?.teamId && isFetching)) {
    return <Loading />;
  }

  const ids = authoriseConfig?.teamId && data ? [authoriseConfig?.teamId, data] : [];

  const isAuthorised = authoriseConfig ? authoriseConfig.handler(ids) : true;
  const isAdminAuthorised = page.admin ? authorisationContext.functions.isAdmin() : true;

  if (!isAuthorised || !isAdminAuthorised || !!error) {
    return <RedirectWithError to="/" error={`Not authorised to view ${page.title}`} />;
  }

  return (<page.component />);
};

const App = () => {
  const location = useLocation();
  const authorisationContext = useAuthorisation();

  /*
  * Find the page with a route that partially matches current location. Use this page path as the route key
  * this prevent a re-render of parent route when a child route changes. Current use is to stop switch transition
  * happening at parent level when child routes change
  */
  const matchingRouteForCurrentLevel = pages.find((p) => pathMatchesWithPathTemplate(location.pathname, p.routeProps.path));
  const routeKey = matchingRouteForCurrentLevel?.routeProps.path;

  const menu: TopLevelMenuItem[] = pages
    .filter((page) => {
      if (!page.mainMenuConfig) {
        return false;
      }

      if (page.authorise) {
        throw new Error('"authorise" page configuration cannot currently be used for a top level page.');
      }

      return page.admin ? authorisationContext.functions.isAdmin() : true;
    })
    .map(({ mainMenuConfig, title, routeProps }) => ({
      group: mainMenuConfig?.group,
      display: title,
      path: routeProps.path as string,
      icon: mainMenuConfig!.iconComponent,
    }));

  return (
    <>
      <EnvironmentBanner />
      <Layout menu={menu}>
        <SwitchTransition>
          <CSSTransition
            key={routeKey}
            timeout={200}
            classNames="fade"
            in
          >
            <Switch location={location} key={routeKey}>
              {pages.map((page) => (
                <Route
                  key={page.title}
                  {...page.routeProps}
                  render={(props) => <AuthorisePage page={page} authorisationContext={authorisationContext} params={props.match.params} />}
                />
                ))}
              <Route
                exact
                path="*"
                render={() => <Redirect to={DEFAULT_ROUTE} />}
              />
            </Switch>
          </CSSTransition>
        </SwitchTransition>
      </Layout>
    </>
  );
};

export default App;
