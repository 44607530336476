import makePage from 'modules/makePage';
import * as parentConstants from 'modules/notifications/NotificationsPage/constants';
import Page from 'modules/Page';
import * as localConstants from './constants';
import NewNotificationPage from './NewNotificationPage';

const page: Page = {
  title: localConstants.TITLE,
  routeProps: {
    path: localConstants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage({
    WrappedPage: NewNotificationPage,
    titleFn: () => localConstants.TITLE,
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
  }),
};

export default page;
