import { CustomUnitFields } from 'providers/api';
import PCStope from '../../DiagraExtended/PCStope';
import { Telemetry } from '../../typesInterfaces';
import { convertDistance, convertVolume } from './conversions';

export function addStopeDataToTelemetry(
  telemetry: Telemetry,
  _stope: PCStope,
  stopePipeType: string,
  imperial: boolean,
  customUnitFields: CustomUnitFields[],
) {
  const stope = { ..._stope };
  telemetry.push(['Stope:', stope.data.stopeName]);
  telemetry.push(['Area:', stope.data.area ?? '']);
  telemetry.push(['Level:', stope.data.level ?? '']);
  telemetry.push(['Volume:', convertVolume(stope.data.volume, imperial, customUnitFields)]);
  telemetry.push(['Pipe: ', stopePipeType]);

  telemetry.push(['Pipe length:', convertDistance(stope.data.pipeLength, imperial, customUnitFields)]);
}

export function updateStopeTelemetry(stope: PCStope, stopePipeType: string, imperial: boolean, customUnitFields: CustomUnitFields[]) {
  const stopeTelemetry: Telemetry = [];
  addStopeDataToTelemetry(
    stopeTelemetry,
    stope,
    stopePipeType,
    imperial,
    customUnitFields,
  );

  return stopeTelemetry;
}
