import { Popover } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useMyUserNotificationsQuery, useUpdateUserNotificationAsReadMutation } from 'providers/api';

import React from 'react';
import { useHistory } from 'react-router';
import NotificationBox from '../NotificationBox';

const NotificationBell = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const markAsReadMutation = useUpdateUserNotificationAsReadMutation('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { data: notifications } = useMyUserNotificationsQuery({
    page: 1,
    pageSize: 10,
    includeRead: false,
  });

  const handleClose = async () => {
    setAnchorEl(null);
    if (notifications && notifications.items.length > 0) {
      const notificationIds = notifications.items.map((n) => n.entityId);
      await markAsReadMutation.mutate({ notificationIds });
    }
  };

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Notifications" arrow>
          <IconButton
            onClick={handleClick}
            size="small"
            style={{ marginLeft: '8px' }}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge badgeContent={notifications?.pager.totalItems} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>

      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 0,
          style: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: '12px',
            marginLeft: '169px',
            width: '350px',
            position: 'relative',
            backgroundColor: '#424242',
            border: '2px solid #4e4e4e',
            borderRadius: '9px',
          },
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: '-8px',
            left: 'calc(50% - 10px)',
            marginLeft: '-10px',
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid #424242',
          }}
        />
        <Box
          padding="8px"
          maxHeight="80vh"
          overflow="auto"
        >
          {notifications?.items.length === 0 ? (
            <Box padding="8px" bgcolor="#BDBDBD" borderRadius="8px">No notifications</Box>
          ) : (
            notifications?.items.map((notification) => (
              <NotificationBox
                key={notification.entityId}
                notification={notification}
              />
            ))
          )}
        </Box>
        <Box
          padding="8px"
          textAlign="center"
          borderRadius="0 0 8px 8px"
          bgcolor="#616161"
          color="#fff"
          style={{
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        }}
          onClick={() => history.push('/notifications')}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#505050';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = '#616161';
          }}
        >
          Show All Notifications
        </Box>
      </Popover>
    </>
  );
};

export default NotificationBell;
