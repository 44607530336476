import Container from '@material-ui/core/Container';
import { BasePageProps } from 'modules/makePage';
import {
  ROUTE_PARAM_IDENTIFIER as ProjectDetailsPageParamIdentifier,
  ROUTE_PATH as ProjectDetailsPageRoute
} from 'modules/project-admin/ProjectDetailsPage/constants';
import { useUpdateRheologyMutation } from 'providers/api';
import { replace } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import RheologyDataForm from '../../shared/NewRheologyPage/RheologyDataForm';
import { EditRheologyPageParams } from './constants';
import { EditRheologyQueries } from '../../shared/EditRheologyPage/useEditRheologyQueries';

const EditRheologyPage = ({ queries }: BasePageProps<EditRheologyQueries>) => {
  const { entityId } = useParams<EditRheologyPageParams>();
  const [rheologyQuery, projectQuery] = queries;
  const rheologyData = rheologyQuery.data;
  const project = projectQuery.data;
  const history = useHistory();

  const mutation = useUpdateRheologyMutation(entityId, {
    onSuccess: () => {
      rheologyQuery.refetch();
      history.push(replace(ProjectDetailsPageParamIdentifier, entityId, ProjectDetailsPageRoute));
    },
  });

  return (
    <Container maxWidth="lg">
      <>
        {
          rheologyData
            && project
            && <RheologyDataForm mutation={mutation} project={project} rheologyData={rheologyData} />
        }
      </>
    </Container>
  );
};

export default EditRheologyPage;
