import {
  FillType,
  PipeDataDto,
  RheologyDataDto,
  StopeDataDto,
  StopeLocationDto,
  ThroughputControlType
} from 'providers/api';
import PCNode from '../DiagraExtended/PCNode';
import PCPipe from '../DiagraExtended/PCPipe';
import PCStope from '../DiagraExtended/PCStope';
import { DictionaryRange, PCFluidSpecification } from '../typesInterfaces';
import ModelState from './ModelState';
import hglSetup from './hgl/setupHGL';
import setupSpools from './setupSpools';
import { updateRecipeState } from './updateRecipeState';

function loadStopes(stopeLocations: StopeLocationDto[], stopes: StopeDataDto[], nodes: Record<string, PCNode>) {
  const stopeObjs: Record<string, PCStope> = {};
  const locations = stopeLocations;
  const stopeArray = stopes;
  for (let i = 0; i < locations.length; i += 1) {
    const stope = locations[i];
    const stopeIndex = stopeArray.findIndex((sl: StopeDataDto) => sl.stopeId === stope.stopeId);
    if (stopeIndex >= 0) {
      const stopeData: StopeDataDto = stopes[stopeIndex];
      stopeObjs[stope.nodeId] = new PCStope(nodes[stope.nodeId], stopeData);
    }
  }
  return stopeObjs;
}

function loadPipes(pipeData: PipeDataDto[]) {
  // const pipeObjs = pipeData.map((pipe: PipeDataDto) => new PCPipe(
  const pipeObjs: Record<string, PCPipe> = {};
  for (let i = 0; i < pipeData.length; i += 1) {
    const pipe = pipeData[i];
    pipeObjs[i] = new PCPipe(
      pipe.name,
      pipe.pipeId,
      pipe.pipeClass,
      pipe.insideDiameter,
      pipe.pressureRating,
      pipe.minimumVelocity,
      pipe.maximumVelocity,
    );
  }
  return pipeObjs;
}

function setPreviousNode(nodes: Record<string, PCNode>) {
  Object.values(nodes).forEach((n) => {
    const node = n;
    const prevId = node.previousId;
    let prev;
    Object.values(nodes).forEach((nodeB) => {
      if (nodeB.pointId === prevId) {
        prev = nodeB;
      }
    });
    if (prev) {
      node.previousNode = prev;
    }
  });
}

function loadNodes(reticulationData: any): Record<string, PCNode> {
  let error = false;
  const nodeObjs: Record<string, PCNode> = {};
  if (typeof reticulationData !== 'undefined') {
    // isStope not dependent on node attribute, rather taken from the stope data
    const input = reticulationData.nodes.map((node: any) => [
      parseInt(node.point.x, 10), // 0: x coordinate
      parseInt(node.point.y, 10), // 1: y coordinate
      parseInt(node.point.z, 10), // 2: z coordinate
      node.nodeId, // 3: node id
      // TODO: must fix issue with pipeId and node.pipeId, the former begins at 1, the latter at 0
      node.pipeId, // 4: pipe type
      parseInt(node.chokeLength, 10), // 5: choke length
      node.previousNodeId, // 6: previous node id
      reticulationData.stopeLocations.some((sl: StopeLocationDto) => sl.nodeId === node.nodeId), // 7: is stope
    ]);
    // Check for errors
    for (let i = 0; i < input.length; i += 1) {
      for (let j = 0; j < 7; j += 1) {
        if (Number.isNaN(input[i][j])) {
          error = true;
          console.log('load error');
          break;
        }
      }
    }

    if (input != null && !error) {
      for (let i = 0; i < input.length; i += 1) {
        const newNode = new PCNode(
          {
            x: input[i][0] as number,
            y: input[i][1] as number,
            z: input[i][2] as number,
          },
          input[i][3] as string,
          input[i][4] as string,
          input[i][5] as number,
          input[i][6] as string,
          input[i][7] as boolean,
        ); // x, y, z, id, pipe type(as index, not id), chokeLength
        nodeObjs[input[i][3]] = newNode;
      }
    }
  }
  setPreviousNode(nodeObjs);
  return nodeObjs;
}

export function loadFluid(rheologyData: RheologyDataDto | RheologyDataDto, dictionaryRange: DictionaryRange, fillType: FillType): PCFluidSpecification {
  return {
    // TODO: errors if undefined
    version: '',
    type: fillType,
    concentrationRange: dictionaryRange.concentration,
    tonnageRange: dictionaryRange.tons,
    binderRange: dictionaryRange.cement,
    drySolidsDensity: rheologyData.tailingsDrySolidsDensity || 1,

    rheologyCoefficients: {
      a: rheologyData.yieldStressCoefficients.coefficient1 ?? 1,
      b: rheologyData.yieldStressCoefficients.coefficient2 ?? 1,
      c: rheologyData.viscosityCoefficients.coefficient1 ?? 1,
      d: rheologyData.viscosityCoefficients.coefficient2 ?? 1,
    },
    ucsCoefficients: rheologyData.ucsCoefficients || {},
    maxPumpPressure: rheologyData.pumpPressure.max, // bar
    heightOfCylinder: rheologyData.heightOfCylinder, // m
    mixerCoefficients: rheologyData.mixerCoefficients,
    carrierFluidDensity: rheologyData.carrierFluidDensity,
    binderDryParticleDensity: rheologyData.binderDryParticleDensity,
  };
}

function findOriginNode(nodeObjs: Record<string, PCNode>) {
  let oNode;
  Object.values(nodeObjs).forEach((node) => {
    if (node.pointId === node.previousNode?.pointId) {
      oNode = node;
    }
  });
  return oNode;
}

export function setupModel(
  data: any,
  onStopeSelect: (stopeId: string) => void,
  returnSelectedRoute: (spoolIds: string[]) => void,
  fillType: FillType,
  initialRheologyDataset: RheologyDataDto,
  throughputControlType: ThroughputControlType,
) {
      // JACOB: **frictionDictionary Refactor - Need to re-add following line:**
  // SEE:   const dictionaryRange = setDictionaryRange(initialRheologyDataset.frictionDictionary);

  const dictionaryRange: DictionaryRange = {
    concentration: initialRheologyDataset.massConcentration,
    tons: initialRheologyDataset.tonnage,
    cement: initialRheologyDataset.binder,
    pumpPressure: {
      min: 0,
      max: initialRheologyDataset.pumpPressure?.max || 0,
      interval: 1,
      default: initialRheologyDataset.pumpPressure?.default || 0,
    },
  };

  console.log(data);
  // TODO: fluid undefined until triggered in multi-rheology version
  const fluid = loadFluid(initialRheologyDataset, dictionaryRange, fillType);
  const nodeObjs = loadNodes(data.reticulationData);
  const pipeObjs = loadPipes(data.reticulationData.pipeData);
  const spoolObjs = setupSpools(nodeObjs, pipeObjs, data.reticulationData.spools);
  const routeObjs = {};
  const stopeObjs = loadStopes(data.reticulationData.stopeLocations, data.reticulationData.stopes, nodeObjs);
  const days = Object.keys(initialRheologyDataset.ucsCoefficients ?? {});
  const originNode = findOriginNode(nodeObjs);
  const hglData = hglSetup();
  const modelState = new ModelState(
    nodeObjs,
    spoolObjs,
    routeObjs,
    pipeObjs,
    stopeObjs,
    originNode,
    hglData,
    fluid,
    dictionaryRange,
    onStopeSelect,
    returnSelectedRoute,
  );

  modelState.setThroughputControlType(throughputControlType);

  const newRecipeState = updateRecipeState(
    modelState.recipeState,
    initialRheologyDataset.massConcentration.min,
    initialRheologyDataset.binder.min,
    fluid.drySolidsDensity,
    fluid.binderDryParticleDensity,
    fluid.carrierFluidDensity,
    throughputControlType === ThroughputControlType.DryTonnage ? initialRheologyDataset.tonnage.min : undefined,
    throughputControlType === ThroughputControlType.FlowRate ? initialRheologyDataset.tonnage.min : undefined,
    throughputControlType === ThroughputControlType.WetTonnage ? initialRheologyDataset.tonnage.min : undefined,
  );

  modelState.setRecipeState(newRecipeState);

  modelState.recipeState.curingDuration = parseInt(days[0], 10);
  console.log(modelState);
  return modelState;
}

//   export interface RheologyDataDto {
//     version: string;
//     /** NodaTimeType:Instant */
//     generated: Instant;
//     data: { [key: string]: number; };
//     targetDaysCoefficients?: { [key: string]: TargetDaysCoefficientsDto; } | undefined;
//     mixerCoefficients: MixerCoefficientsDto;
//     maxPumpPressure?: number;
//     specificGravity?: number;
//     yieldStressA?: number;
//     yieldStressB?: number;
//     heightOfCylinder?: number | undefined;
// }

// export interface Range {
//   min: number;
//   max: number;
//   data: number[];
//   interval: number;
// }

// export interface DictionaryRange {
//   concentration: Range;
//   tons: Range;
//   pipes: Range;
//   cement: Range;
// }
