import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { UnitBase } from 'hooks/useDisplayUnits/useDisplayUnits';
import { UnitSystem, CustomUnitFields } from 'providers/api';
import { ChangeEvent } from 'react';
import { round, sanitiseNumber } from 'utils';
import { isCustomUnitField } from 'utils/unitConversions';

const useStyles = makeStyles(() => ({
  reducedLabel: {
    '& p.MuiTypography-body1': {
      fontSize: '.7rem',
    },
  },
}));

const getNumberValue = (value: unknown, unitInfo: UnitBase, isCustom: boolean, roundingFn?: (v: number) => number) => {
  const numberValue = sanitiseNumber(value);
  const rounding = roundingFn || ((v: number) => v);

  if (!numberValue) {
    return undefined;
  }

  return isCustom ? rounding(unitInfo.imperial.conversion(value as number)) : rounding(numberValue);
};

interface UnitSystemNumberFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  unitSystem: UnitSystem;
  unitInfo: UnitBase;
  decimalPlaces: number;
  customUnitFields: CustomUnitFields[];
}

const UnitSystemNumberField = (props: UnitSystemNumberFieldProps) => {
  const classes = useStyles();
  const { value, unitSystem, unitInfo, decimalPlaces, customUnitFields, onChange, ...other } = props;
  const isCustom = isCustomUnitField(unitSystem, customUnitFields, unitInfo.name);

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const valueAsNumber = sanitiseNumber(event.target.value) ?? 0;

    // Metric is the standardised unit so always return in metric
    const newValue = isCustom ? unitInfo.metric.conversion(valueAsNumber) : valueAsNumber;

    onChange && onChange({
      ...event,
      target: {
        ...event.target,
        value: newValue.toString(),
      },
    });
  };

  const displayValue = getNumberValue(value, unitInfo, isCustom, (number) => round(number, decimalPlaces)) ?? 0;

  return (
    <TextField
      {...other}
      value={displayValue}
      onChange={handleOnChange}
      type="number"
      fullWidth
      variant="outlined"
      className={classes.reducedLabel}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isCustom ? unitInfo.imperial.abbreviation : unitInfo.metric.abbreviation}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default UnitSystemNumberField;
