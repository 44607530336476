import { capitalize } from 'utils';
import {
  MarkUserNotificationsAsReadCommand,
  NotificationDto,
  NotificationForm,
  PagedListOfNotificationDto,
  PagedListOfUserNotificationDto
} from './api';
import {
  PageParams,
  makeCreateMutation,
  makeDeleteMutation,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const NOTIFICATIONS_KEY = 'notifications';
const NOTIFICATION_KEY = 'notification';

const USER_NOTIFICATIONS_KEY = 'user_notifications';
const USER_NOTIFICATION_KEY = 'user_notification';
const USER_NOTIFICATIONS_KEY_ALL = 'user_notifications_all';
export const useCreateGlobalNotificationMutation = makeCreateMutation<void, NotificationForm>(
  NOTIFICATIONS_KEY,
  (api) => (mutation) => api.notifications.createNotifications(mutation),
  () => 'A global notification has been successfully sent!',
  [USER_NOTIFICATIONS_KEY],
);

export const useListAllNotificationsQuery = makeListQuery<PageParams, PagedListOfNotificationDto>(
  NOTIFICATIONS_KEY,
  (api, params) => () => api.notifications.listAllNotifications(params.page, params.pageSize),
);

export const useDeleteNotificationMutation = makeDeleteMutation<void, NotificationDto>(
  NOTIFICATION_KEY, NOTIFICATIONS_KEY,
  (api) => (notification) => api.notifications.delete(notification.entityId),
  (mutation) => `Notification '${capitalize(mutation.title)}' successfully deleted`,
  (mutation) => mutation.entityId,
);

export const useListAllUserNotificationsQuery = makeListQuery<PageParams, PagedListOfUserNotificationDto>(
  USER_NOTIFICATIONS_KEY_ALL,
  (api, params) => () => api.userNotifications.listAllUserNotifications(params.page, params.pageSize, true),
);

export interface MyUserNotificationsQueryParams extends PageParams {
  includeRead?: boolean;
}

export const useMyUserNotificationsQuery = makeListQuery<MyUserNotificationsQueryParams, PagedListOfUserNotificationDto>(
  USER_NOTIFICATIONS_KEY,
  (api, params) => () => api.userNotifications.myUserNotifications(params.page, params.pageSize, params.includeRead),
);

export const useUpdateUserNotificationAsReadMutation = makeUpdateMutation<void, MarkUserNotificationsAsReadCommand>(
  USER_NOTIFICATION_KEY,
  USER_NOTIFICATIONS_KEY,
  (api) => (command) => api.userNotifications.updateUserNotificationAsRead(command),
  () => 'Notification marked as read',
);
