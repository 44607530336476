import ClientAdminModule from './client-admin';
import NotificationsModule from './notifications';
import NotificationsAdminModule from './notifications-admin';
import ProjectModule from './project';
import ProjectAdminModule from './project-admin';
import UserAdminModule from './user-admin';

export {
  ClientAdminModule, NotificationsAdminModule, NotificationsModule, ProjectAdminModule, ProjectModule, UserAdminModule,
};

const pages = [
  ...ProjectModule.pages,
  ...ClientAdminModule.pages,
  ...ProjectAdminModule.pages,
  ...UserAdminModule.pages,
  ...NotificationsAdminModule.pages,
  ...NotificationsModule.pages,
];

export default pages;
