import AlertText from 'components/AlertText';
import { BasePageProps } from 'modules/makePage';
import { ROUTE_PARAM_IDENTIFIER as routePathIdentifier, STOPES_ROUTE_PATH as stopesRoutePath } from 'modules/project/ProjectAreaPage/constants';
import { useCreatePourMutation } from 'providers/api';
import { replace } from 'ramda';
import { useHistory, useParams } from 'react-router';
import { ProjectProvider } from '../providers/project';
import { NewPourPageParams } from './constants';
import NewPourForm from './PourForm/NewPourForm';
import { NewPourQueries } from './useNewPourQueries';

const NewPourPage = ({ queries }: BasePageProps<NewPourQueries>) => {
  const { projectId, stopeId } = useParams<NewPourPageParams>();
  const [recipeQuery, projectQuery] = queries;
  const recipe = recipeQuery.data;
  const project = projectQuery.data;
  const history = useHistory();

  const createMutation = useCreatePourMutation({
    onSuccess: () => {
      history.push(
        `${replace(
          routePathIdentifier,
          projectId,
          stopesRoutePath,
        )}/${stopeId}/pours`,
      );
    },
  });

  if (!recipe || !project) {
    return <AlertText severity="info">Loading required data</AlertText>;
  }

  return (
    <ProjectProvider project={project}>
      <NewPourForm
        stopeId={stopeId}
        recipe={recipe}
        mutation={createMutation}
        unitSystemPreference={project.unitSystemPreference}
        customUnitFields={project.customUnitFields}
        fillType={project.fillType}
      />
    </ProjectProvider>
  );
};

export default NewPourPage;
