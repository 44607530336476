import Page from 'modules/Page';
import makePage from 'modules/makePage';
import * as parentConstants from '../../constants';
import useEditRheologyQueries, { EditRheologyQueries } from '../../../../shared/EditRheologyPage/useEditRheologyQueries';
import EditRheologyPage from './EditRheologyPage';
import * as constants from './constants';
import { EditRheologyPageParams } from './constants';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: false,
  component: makePage<EditRheologyPageParams, EditRheologyQueries>({
    WrappedPage: EditRheologyPage,
    titleFn: ([rheologyQuery]) => {
      const title = rheologyQuery.data ? rheologyQuery.data.reference : '';
      return `Edit ${title} Rheology Data`;
    },
    parentPathTemplate: parentConstants.DETAILS_ROUTE_PATH,
    parentTitleFn: () => parentConstants.DETAILS_TITLE,
    pageDataHook: useEditRheologyQueries,
  }),
};

export default page;
