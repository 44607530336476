import { UnitSystem, CustomUnitFields } from 'providers/api';
import Vector from '../../Diagra/Vector';
import PCNode from '../../DiagraExtended/PCNode';
import { RoutePoint, Telemetry } from '../../typesInterfaces';
import ModelState from '../ModelState';
import { convertSpeed } from '../Telemetry/conversions';
import updateNodeTelemetry from '../Telemetry/updateNodeTelemetry';

export default class GuiState {
  panStatus = false;

  rotateStatus = false;

  addStopeStatus = false;

  showPipeTypesStatus = false;

  isolateRouteStatus = false;

  activeRecipeStatus = false;

  hoverId: string | undefined;

  hitBool = false;

  screenWidth = window.innerWidth;

  screenHeight = window.innerHeight;

  modelCanvas: HTMLCanvasElement;

  bufferCanvas: HTMLCanvasElement;

  telemetryCanvas: HTMLCanvasElement;

  hglCanvas: HTMLCanvasElement;

  pipeColorCanvas: HTMLCanvasElement;

  drawerWidth: number;

  unitSystemPreference: UnitSystem;

  mainMenuWidth: number;

  mouseDownBool = false;

  rotateAxis = '';

  lastMousePos = new Vector();

  centreOfGravity = new Vector();

  currentRotation = new Vector();

  mousePos = new Vector();

  modelState: ModelState;

  modelContext: CanvasRenderingContext2D | null;

  bufferContext: CanvasRenderingContext2D | null;

  customUnitFields: CustomUnitFields[];

  constructor(
    modelCanvas: HTMLCanvasElement,
    bufferCanvas: HTMLCanvasElement,
    hglCanvas: HTMLCanvasElement,
    telemetryCanvas: HTMLCanvasElement,
    pipeColorCanvas : HTMLCanvasElement,
    drawerWidth: number,
    unitSystemPreference: UnitSystem,
    mainMenuWidth: number,
    modelState: ModelState,
    customUnitFields: CustomUnitFields[],
  ) {
    this.modelCanvas = modelCanvas;
    this.bufferCanvas = bufferCanvas;
    this.hglCanvas = hglCanvas;
    this.telemetryCanvas = telemetryCanvas;
    this.pipeColorCanvas = pipeColorCanvas;
    this.drawerWidth = drawerWidth;
    this.unitSystemPreference = unitSystemPreference;
    this.mainMenuWidth = mainMenuWidth;
    this.modelState = modelState;
    this.modelContext = modelCanvas.getContext('2d');
    this.bufferContext = bufferCanvas.getContext('2d');
    this.customUnitFields = customUnitFields;
  }

  setMousePos(pos: Vector) { // on mouseMove: hit and hover
    const { nodes, selectedNode, selectedStope, selectedRoute, stopePipeType } = this.modelState;
    let routePoint: RoutePoint | undefined;
    this.unHoverNode();
    if (!this.panStatus && !this.rotateStatus) {
      const hitNodes = Object.values(nodes).filter((n) => n.hitTest(pos));
      let minDist = 15;
      let hitNode = hitNodes[0];
      hitNodes.forEach((node) => {
        const dist = node.screenVector.distanceXYTo(pos);
        if (dist < minDist) {
          hitNode = node;
          minDist = dist;
        }
      });
      this.hitBool = false;
      if (typeof hitNode !== 'undefined') {
        this.hoverNode(hitNode.pointId, hitNode);
      } else if (typeof selectedNode !== 'undefined') {
          if (typeof selectedRoute !== 'undefined') {
            routePoint = selectedRoute.routePoints.find((rp) => rp.point.pointId === selectedNode.pointId);
          }
          this.modelState.nodeTelemetry = updateNodeTelemetry(selectedNode, routePoint, stopePipeType, this.unitSystemPreference, this.customUnitFields, selectedStope);
      }
    }
  }

  unHoverNode() {
    const prevHovNode = Object.values(this.modelState.nodes).find((n) => n.pointId === this.hoverId);
    if (prevHovNode) prevHovNode.hitBool = false;
    this.hoverId = undefined;
  }

  // Injects from React
  hoverNode(nodeId: string, _node?: PCNode) {
    const { nodes, stopes, selectedRoute, stopePipeType, hglData } = this.modelState;
    let node = _node;
    if (typeof node === 'undefined') node = Object.values(nodes).find((n) => n.pointId === nodeId);
    if (typeof node !== 'undefined') {
      this.unHoverNode();
      node.hitBool = true;
      this.hitBool = true;
      this.hoverId = nodeId;
      let routePts: RoutePoint[] = [];
      let routePoint: RoutePoint | undefined;
      if (typeof selectedRoute !== 'undefined') {
        routePts = selectedRoute.routePoints;
        routePoint = routePts.find((rp) => rp.point.pointId === nodeId);
        if (typeof routePoint?.distanceFromPump !== 'undefined') hglData.xLine = routePoint.distanceFromPump;
      }
      if (node.isStope) {
        const stope = Object.values(stopes).find((s) => s.originNode.pointId === nodeId);
        this.modelState.nodeTelemetry = updateNodeTelemetry(node, routePoint, stopePipeType, this.unitSystemPreference, this.customUnitFields, stope);
      } else {
        this.modelState.nodeTelemetry = updateNodeTelemetry(node, routePoint, stopePipeType, this.unitSystemPreference, this.customUnitFields);
      }
      this.modelCanvas.style.cursor = this.hitBool ? 'pointer' : '';
    }
  }

  triggerPan() {
    this.panStatus = !this.panStatus;
    if (this.panStatus) {
      this.rotateStatus = false;
      // panStatus.deselect([1, 2], buttons)
    }
    console.log('pan button', this.panStatus);
  }

  triggerRotate() {
    this.rotateStatus = !this.rotateStatus;
    if (this.rotateStatus) {
      this.panStatus = false;
    }
    console.log('rotate button', this.rotateStatus);
  }

  triggerAddStope() {
    this.addStopeStatus = !this.addStopeStatus;
    if (this.addStopeStatus) {
      this.panStatus = false;
      this.rotateStatus = false;
    }
  }

  triggerPipeTypes() {
    this.showPipeTypesStatus = !this.showPipeTypesStatus;
    // const { spools } = this.modelState;
    // if (this.showPipeTypesStatus) {
    //   Object.values(spools).forEach((_s) => {
    //     const s = _s;
    //     const pipeId = parseInt(s.pipeId, 10);
    //     s.pipeColour = PCConstants.PIPE_STYLES[pipeId];
    //   });
    // }
  }

  triggerIsolateRoute() {
    this.isolateRouteStatus = !this.isolateRouteStatus;
  }

  updateLegend() {
    const { pipes } = this.modelState;
    const legend: Telemetry = [];
    Object.values(pipes).forEach((pipe) => {
      let v = '';
      if (typeof pipe.velocity !== 'undefined') v = convertSpeed(pipe.velocity, this.unitSystemPreference === 10, this.customUnitFields);
      legend.push([`${pipe.pipeId}.  ${pipe.name}`, v]);
    });
    this.modelState.pipeLegend = legend;
  }
}
