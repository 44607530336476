import { waterBinderRatioFromConcentrationAndBinder } from 'modules/project/ProjectAreaPage/ProjectStopesPage/canvas/PCFunctions/PCOtherFunctions';
import { DictionaryRange } from 'modules/project/ProjectAreaPage/ProjectStopesPage/canvas/typesInterfaces';
import {
  CustomUnitFields,
  DisplayUnits,
  FillType,
  HydraulicBackfillSpecificationDto,
  HydraulicRecipeSpecificationDto,
  MixerCoefficientsDto,
  PasteBackfillSpecificationDto,
  PasteRecipeSpecificationDto,
  ThroughputControlType,
  UnitSystem
} from 'providers/api';
import { generateMassBalanceFromFlowRate, generateMassBalanceFromTonnage } from 'utils';
import {
  batchMixerPower,
  continuousMixerPower,
  resolveSlump,
  resolveYieldStress
} from 'utils/backfill';
import { isNumber, round } from 'utils/number';
import {
  densityImperialToMetric,
  densityMetricToImperial,
  distanceImperialToMetric,
  distanceMetricToImperial,
  flowRateImperialToMetric,
  flowRateMetricToImperial,
  powerImperialToMetric,
  powerMetricToImperial,
  pressureImperialToMetric,
  pressureMetricToImperial,
  pumpPressureImperialToMetric,
  pumpPressureMetricToImperial,
  smallDistanceImperialToMetric,
  smallDistanceMetricToImperial,
  speedImperialToMetric,
  speedMetricToImperial,
  tonnageImperialToMetric,
  tonnageMetricToImperial,
  volumeImperialToMetric,
  volumeMetricToImperial,
  isCustomUnitField
} from 'utils/unitConversions';

const CONTINUOUS_MIXER_KEYS = ['continuousMixerCoefficientM', 'continuousMixerCoefficientC', 'continuousMixerCoefficientN', 'continuousMixerCoefficientD'];
const BATCH_MIXER_KEYS = ['batchMixerCoefficientM', 'batchMixerCoefficientN', 'batchMixerCoefficientD'];

const hasNullOrUndefinedValues = (object: any, keys: string[]) => keys.some((key) => object[key] === null || object[key] === undefined);

// INFO: false positive
// eslint-disable-next-line no-shadow
export enum BasicDisplayUnits {
  PressureUCS = 0,
  Density = 10,
  DryTonnage = 20,
  WetTonnage = 25,
  Distance = 30,
  Volume = 40,
  Speed = 50,
  PressurePump = 60,
}

type UnitOfMeasurement = {
  abbreviation: string,
  conversion: (currentValue: number) => number,
  decimalPlaces?: number;
}

export interface UnitBase {
  name: string;
  warning?: string;
  decimalPlaces: number;
  metric: UnitOfMeasurement;
  imperial: UnitOfMeasurement;
}

export interface UnitBaseWithValue extends UnitBase {
  value: number | undefined;
}

// eslint-disable-next-line import/prefer-default-export
export const additionalUnitLabels: Record<DisplayUnits, UnitBase> = {
  [DisplayUnits.DrySolidFeedRate]: {
    name: 'Dry Tonnage',
    warning: 'Dry tonnage requires a value for solids throughput',
    decimalPlaces: 0,
    metric: {
      abbreviation: 't/h',
      conversion: tonnageImperialToMetric,
    },
    imperial: {
      abbreviation: 'tn/h',
      conversion: tonnageMetricToImperial,
    },
  },

  [DisplayUnits.SlurryFeedRate]: {
    name: 'Slurry Tonnage',
    warning: 'Slurry tonnage requires a value for specific gravity',
    decimalPlaces: 0,
    metric: {
      abbreviation: 't/h',
      conversion: tonnageImperialToMetric,
    },
    imperial: {
      abbreviation: 'tn/h',
      conversion: tonnageMetricToImperial,
      decimalPlaces: 1,
    },
  },

  [DisplayUnits.FlowRate]: {
    name: 'Flow Rate',
    warning: 'Flow rate requires a value for specific gravity',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'm\u00B3/h',
      conversion: flowRateImperialToMetric,
    },
    imperial: {
      abbreviation: 'gpm',
      conversion: flowRateMetricToImperial,
    },
  },

  [DisplayUnits.SolidsDensity]: {
    name: 'Solids Density',
    warning: 'Solids density requires a value for specific gravity',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'kg/m\u00B3',
      conversion: densityImperialToMetric,
    },
    imperial: {
      abbreviation: 'lb/ft\u00B3',
      conversion: densityMetricToImperial,
    },
  },

  [DisplayUnits.SlurryDensity]: {
    name: 'Slurry Density',
    warning: 'Slurry density requires a value for specific gravity',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'kg/m\u00B3',
      conversion: densityImperialToMetric,
    },
    imperial: {
      abbreviation: 'lb/ft\u00B3',
      conversion: densityMetricToImperial,
    },
  },

  [DisplayUnits.ContinuousMixerPowerDraw]: {
    name: 'Cont. Mixer Power',
    warning: 'Continuous Mixer Power requires values for specificGravity, yieldStressA, yieldStressB, and all continuous mixer coefficients',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'kW',
      conversion: powerImperialToMetric,
    },
    imperial: {
      abbreviation: 'hp',
      conversion: powerMetricToImperial,
    },
  },

  [DisplayUnits.BatchMixerPowerDraw]: {
    name: 'Bat. Mixer Power',
    warning: 'Batch Mixer Power requires values for specificGravity, yieldStressA, yieldStressB, and all batch mixer coefficients',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'kW',
      conversion: powerImperialToMetric,
    },
    imperial: {
      abbreviation: 'hp',
      conversion: powerMetricToImperial,
    },
  },

  [DisplayUnits.Slump]: {
    name: 'Slump',
    warning: 'slump requires values for specificGravity, yieldStressA, and yieldStressB',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'mm',
      conversion: smallDistanceImperialToMetric,
    },
    imperial: {
      abbreviation: 'in',
      conversion: smallDistanceMetricToImperial,
    },
  },

  [DisplayUnits.YieldStress]: {
    name: 'Yield Stress',
    warning: 'yield stress requires values for massConcentration, yieldStressA, and yieldStressB',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'pa',
      conversion: (currentNumber: number) => currentNumber,
    },
    imperial: {
      abbreviation: 'pa',
      conversion: (currentNumber: number) => currentNumber,
    },
  },

  [DisplayUnits.WBRatio]: {
    name: 'W:B Ratio',
    warning: 'W:B Ratio requires values for totalDryCentrationByMass and binderConcentration',
    decimalPlaces: 1,
    metric: {
      abbreviation: '',
      conversion: (currentNumber: number) => currentNumber,
    },
    imperial: {
      abbreviation: '',
      conversion: (currentNumber: number) => currentNumber,
    },
  },
};

export const unitLabels: Record<BasicDisplayUnits, UnitBase> = {
  [BasicDisplayUnits.Volume]: {
    name: 'Volume',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'm\u00B3',
      conversion: volumeImperialToMetric,
    },
    imperial: {
      abbreviation: 'ft\u00B3',
      conversion: volumeMetricToImperial,
    },
  },

  [BasicDisplayUnits.Distance]: {
    name: 'Distance',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'm',
      conversion: distanceImperialToMetric,
    },
    imperial: {
      abbreviation: 'ft',
      conversion: distanceMetricToImperial,
    },
  },

  [BasicDisplayUnits.WetTonnage]: {
    name: 'Wet Tonnage',
    decimalPlaces: 0,
    metric: {
      abbreviation: 't/h',
      conversion: tonnageImperialToMetric,
    },
    imperial: {
      abbreviation: 'tn/h',
      conversion: tonnageMetricToImperial,
    },
  },

  [BasicDisplayUnits.DryTonnage]: {
    name: 'Dry Tonnage',
    decimalPlaces: 0,
    metric: {
      abbreviation: 't/h',
      conversion: tonnageImperialToMetric,
    },
    imperial: {
      abbreviation: 'tn/h',
      conversion: tonnageMetricToImperial,
    },
  },

  [BasicDisplayUnits.Density]: {
    name: 'Density',
    decimalPlaces: 3,
    metric: {
      abbreviation: 'kg/m\u00B3',
      conversion: densityImperialToMetric,
    },
    imperial: {
      abbreviation: 'lb/ft\u00B3',
      conversion: densityMetricToImperial,
    },
  },

  [BasicDisplayUnits.PressureUCS]: {
    name: 'Strength',
    decimalPlaces: 0,
    metric: {
      abbreviation: 'kPa',
      conversion: pressureImperialToMetric,
    },
    imperial: {
      abbreviation: 'psi',
      conversion: pressureMetricToImperial,
    },
  },

  [BasicDisplayUnits.PressurePump]: {
    name: 'Pump Pressure',
    decimalPlaces: 1,
    metric: {
      abbreviation: 'bar',
      conversion: pumpPressureImperialToMetric,
    },
    imperial: {
      abbreviation: 'psi',
      conversion: pumpPressureMetricToImperial,
    },
  },

  [BasicDisplayUnits.Speed]: {
    name: 'Velocity',
    decimalPlaces: 1,
    metric: {
      abbreviation: 'm/s',
      conversion: speedImperialToMetric,
    },
    imperial: {
      abbreviation: 'ft/s',
      conversion: speedMetricToImperial,
    },
  },
};

export const convertIfRequired = (value: number, unitSystem: UnitSystem, unit: UnitBase) => (unitSystem === UnitSystem.Metric
  ? value
  : unit.imperial.conversion(value));

export const getAbbreviation = (unit: UnitBase, unitSystem: UnitSystem, customUnitFields: CustomUnitFields[]) => (!isCustomUnitField(unitSystem, customUnitFields, unit.name)
  ? unit.metric.abbreviation
  : unit.imperial.abbreviation);

export const convertValueToImperialdsfdsf = (unit: UnitBase, value: number, unitSystem: UnitSystem) => (unitSystem === UnitSystem.Metric
  ? value
  : unit.imperial.conversion(value));

export interface AdditionalDisplayUnitsOutput {
  additionalDisplayUnits: Record<DisplayUnits, UnitBaseWithValue>;
  warnings: Record<DisplayUnits, number | undefined>;
}

export interface AdditionalDisplayUnitsInputs {
  key: string;
  displayUnitPreferences: DisplayUnits[];
  throughputControlType: ThroughputControlType;
  specification?: PasteBackfillSpecificationDto | HydraulicBackfillSpecificationDto;
  selectedSpecification?: PasteRecipeSpecificationDto | HydraulicRecipeSpecificationDto;

  // Rheology data
  tailingsSolidsDensity?: number,
  binderParticleDensity?: number,
  carrierFluidDensity?: number,
  specificGravity?: number;
  yieldStressA?: number;
  yieldStressB?: number;
  mixerCoefficients?: MixerCoefficientsDto;
  heightOfCylinder?: number;
  fillType: FillType;
  limits?: DictionaryRange;
}

const resolveContinuousMixerPowerDraw = (slump?: number, slurryFeedRate?: number, mixerCoefficients?: MixerCoefficientsDto) => {
  if (!slump || !slurryFeedRate || !mixerCoefficients || hasNullOrUndefinedValues(mixerCoefficients, CONTINUOUS_MIXER_KEYS)) return undefined;
  return continuousMixerPower(
    slump,
    slurryFeedRate,
    mixerCoefficients.continuousMixerCoefficientM!,
    mixerCoefficients.continuousMixerCoefficientC!,
    mixerCoefficients.continuousMixerCoefficientN!,
    mixerCoefficients.continuousMixerCoefficientD!,
  );
};

const resolveBatchMixerPowerDraw = (slump?: number, slurryFeedRate?: number, mixerCoefficients?: MixerCoefficientsDto) => {
  if (!slump || !slurryFeedRate || !mixerCoefficients || hasNullOrUndefinedValues(mixerCoefficients, BATCH_MIXER_KEYS)) return undefined;
  return batchMixerPower(
    slump,
    slurryFeedRate,
    mixerCoefficients.continuousMixerCoefficientM!,
    mixerCoefficients.continuousMixerCoefficientN!,
    mixerCoefficients.continuousMixerCoefficientD!,
  );
};

const useDisplayUnits = (inputs: AdditionalDisplayUnitsInputs[]): Record<string, AdditionalDisplayUnitsOutput> => inputs.reduce((results, info) => {
  const throughput = info.specification?.throughput ?? info.limits!.tons.default ?? 0;
  const binderTailingsRatio = info.specification ? (info.specification as HydraulicBackfillSpecificationDto).binderTailingsRatio : 0;
  const pasteBinderContent = info.selectedSpecification ? (info.selectedSpecification as PasteRecipeSpecificationDto).binderContent : 0;
  const binderConcentration = info.fillType === FillType.Paste
    ? pasteBinderContent
    : binderTailingsRatio / (1 + binderTailingsRatio);

  const massBalanceInputs = {
    totalTonnage: throughput,
    totalDryConcentrationByMass: info.selectedSpecification?.massConcentration ?? 0,
    binderConcentration,
    tailingsSolidsDensity: info.tailingsSolidsDensity ?? 0,
    binderParticleDensity: info.binderParticleDensity ?? 0,
    carrierFluidDensity: info.carrierFluidDensity ?? 0,
  };
  let massBalance;

  switch (info.throughputControlType) {
    case ThroughputControlType.DryTonnage:
      massBalance = generateMassBalanceFromTonnage({ ...massBalanceInputs, isDryTonnage: true });
      break;
    case ThroughputControlType.WetTonnage:
      massBalance = generateMassBalanceFromTonnage({ ...massBalanceInputs, isDryTonnage: false });
      break;
    case ThroughputControlType.FlowRate:
      massBalance = generateMassBalanceFromFlowRate({ ...massBalanceInputs, totalFlowRate: throughput });
      break;
    default:
      throw new Error('Unable to generate mass balance as this project has no associated fill type');
  }

  const yieldStress = resolveYieldStress(info.yieldStressA, info.yieldStressB, info.selectedSpecification?.massConcentration);
  const slump = resolveSlump(yieldStress, massBalance?.slurryDensity, info.heightOfCylinder);
  const continuousMixerPowerDraw = resolveContinuousMixerPowerDraw(slump, massBalance?.totalWetTonnage, info.mixerCoefficients);
  const batchMixerPowerDraw = resolveBatchMixerPowerDraw(slump, massBalance?.totalWetTonnage, info.mixerCoefficients);
  const wbRatio = waterBinderRatioFromConcentrationAndBinder(massBalanceInputs.totalDryConcentrationByMass / 100, massBalanceInputs.binderConcentration / 100);

  const additionalDisplayUnits: Record<DisplayUnits, UnitBaseWithValue> = {
    [DisplayUnits.DrySolidFeedRate]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.DrySolidFeedRate) && massBalance?.totalDryTonnage
        ? round(massBalance?.totalDryTonnage)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.DrySolidFeedRate],
    },
    [DisplayUnits.SlurryFeedRate]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.SlurryFeedRate) && massBalance?.totalWetTonnage
        ? round(massBalance?.totalWetTonnage)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.SlurryFeedRate],
    },
    [DisplayUnits.FlowRate]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.FlowRate) && massBalance?.totalFlowRate
        ? round(massBalance?.totalFlowRate)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.FlowRate],
    },
    [DisplayUnits.SolidsDensity]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.SolidsDensity) && massBalance?.totalDrySolidsDensity
        ? round(massBalance?.totalDrySolidsDensity)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.SolidsDensity],
    },
    [DisplayUnits.SlurryDensity]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.SlurryDensity) && massBalance?.slurryDensity
        ? round(massBalance?.slurryDensity)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.SlurryDensity],
    },
    [DisplayUnits.BatchMixerPowerDraw]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.BatchMixerPowerDraw) && batchMixerPowerDraw
        ? round(batchMixerPowerDraw)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.BatchMixerPowerDraw],
    },
    [DisplayUnits.ContinuousMixerPowerDraw]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.ContinuousMixerPowerDraw) && continuousMixerPowerDraw
        ? round(continuousMixerPowerDraw)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.ContinuousMixerPowerDraw],
    },
    [DisplayUnits.Slump]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.Slump) && slump
        ? round(slump)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.Slump],
    },
    [DisplayUnits.YieldStress]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.YieldStress) && yieldStress
        ? round(yieldStress)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.YieldStress],
    },
    [DisplayUnits.WBRatio]: {
      value: info.displayUnitPreferences.includes(DisplayUnits.WBRatio) && wbRatio
        ? round(wbRatio)
        : undefined,
      ...additionalUnitLabels[DisplayUnits.WBRatio],
    },
  };

  // create display warnings for any values that should be displayed and that don't have a number value
  const warnings: Record<DisplayUnits, string | undefined> = {
    [DisplayUnits.DrySolidFeedRate]: !info.displayUnitPreferences.includes(DisplayUnits.DrySolidFeedRate) || isNumber(massBalance?.totalDryTonnage)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.DrySolidFeedRate].warning,
    [DisplayUnits.SlurryFeedRate]: !info.displayUnitPreferences.includes(DisplayUnits.SlurryFeedRate) || isNumber(massBalance?.totalWetTonnage)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.SlurryFeedRate].warning,
    [DisplayUnits.FlowRate]: !info.displayUnitPreferences.includes(DisplayUnits.FlowRate) || isNumber(massBalance?.totalFlowRate)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.FlowRate].warning,
    [DisplayUnits.SolidsDensity]: !info.displayUnitPreferences.includes(DisplayUnits.SolidsDensity) || isNumber(massBalance?.totalDrySolidsDensity)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.SolidsDensity].warning,
    [DisplayUnits.SlurryDensity]: !info.displayUnitPreferences.includes(DisplayUnits.SlurryDensity) || isNumber(massBalance?.slurryDensity)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.SlurryDensity].warning,
    [DisplayUnits.BatchMixerPowerDraw]: !info.displayUnitPreferences.includes(DisplayUnits.BatchMixerPowerDraw) || isNumber(batchMixerPowerDraw)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.BatchMixerPowerDraw].warning,
    [DisplayUnits.ContinuousMixerPowerDraw]: !info.displayUnitPreferences.includes(DisplayUnits.ContinuousMixerPowerDraw)
      || isNumber(continuousMixerPowerDraw)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.ContinuousMixerPowerDraw].warning,
    [DisplayUnits.Slump]: !info.displayUnitPreferences.includes(DisplayUnits.Slump) || isNumber(slump)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.Slump].warning,
    [DisplayUnits.YieldStress]: !info.displayUnitPreferences.includes(DisplayUnits.YieldStress) || isNumber(yieldStress)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.YieldStress].warning,
    [DisplayUnits.WBRatio]: !info.displayUnitPreferences.includes(DisplayUnits.WBRatio) || isNumber(wbRatio)
      ? undefined
      : additionalDisplayUnits[DisplayUnits.WBRatio].warning,
  };

  return {
    ...results,
    [info.key]: { additionalDisplayUnits, warnings },
  };
},
  {});

export default useDisplayUnits;
