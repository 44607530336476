import { LocalDateTime } from '@js-joda/core';
import { ResponsiveGridColDef } from 'components/DataGrid';
import DataMultipleTransactionBox from 'components/DataTransactionBox/DataMultipleTransactionBox';
import {
  MineModelDto,
  ProjectDto,
  RheologyDataSummaryDto,
  useDeleteRheologyDatasetMutation
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { replace } from 'ramda';
import { useHistory } from 'react-router';
import { displayTemporal } from 'utils';
import useSaveRheologyData from 'modules/shared/useSaveRheologyData';
import { UseQueryResult } from 'react-query';
import {
  ROUTE_PARAM_IDENTIFIER as NewRheologyPageParamIdentifier,
  ROUTE_PATH as NewRheologyPageRoute
} from './NewRheologyPage/constants';
import RheologyDataSummaryActionMenu from './EditRheologyPage/RheologyDataSummaryActionMenu';

const generateRheologyDataColumns = (
  projectName: string,
  mineModelId: string,
  onRheologyDatasetDelete: (rheologyDatasetMineModelId: string, rheologyDataset: RheologyDataSummaryDto) => void,
): ResponsiveGridColDef[] => ([
  {
    field: 'reference',
    headerName: 'Reference',
    flex: 1,
    resizable: false,
    sortable: false,
    filterable: false,
  },
  {
    field: 'generated',
    headerName: 'Generated',
    flex: 1,
    resizable: false,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => displayTemporal(LocalDateTime.ofInstant(row.generated)),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    resizable: false,
    sortable: false,
    filterable: false,
    width: 110,
    renderCell: ({ row }) => (
      <>
        <RheologyDataSummaryActionMenu
          projectName={projectName}
          rheologyDataSummary={(row as any) as RheologyDataSummaryDto}
          mineModelId={mineModelId}
          onDelete={() => onRheologyDatasetDelete(mineModelId, row as RheologyDataSummaryDto)}
        />
      </>
    ),
  },
]);
interface RheologyDataSetDataTransactionBoxProps {
  project: ProjectDto;
  mineModel: MineModelDto;
  mineModelQuery: UseQueryResult<MineModelDto | undefined, unknown>;
}

const RheologyDataSetDataTransactionBox = ({ project, mineModel, mineModelQuery }: RheologyDataSetDataTransactionBoxProps) => {
  const confirm = useConfirmation();
  const deleteRheologyDatasetMutation = useDeleteRheologyDatasetMutation();
  const history = useHistory();

  const handleDeleteRheologyDataset = (mineModelId: string, rheologyDataset: RheologyDataSummaryDto) => {
    confirm({
      variant: 'danger',
      description: `Are you sure you want to remove '${rheologyDataset.reference ?? 'this rheology dataset'}'?`,
    }).then(
      () => deleteRheologyDatasetMutation.mutate(
        {
          mineModelId,
          rheologyDatasetId: rheologyDataset.rheologyDataSetId,
          rheologyDatasetReference: rheologyDataset.reference,
        },
        {
          onSuccess: () => {
            window.location.reload();
          },
        },
      ),
    );
  };

  const {
    errors: rheologyErrors,
    isSubmitting: isSubmittingRheology,
  } = useSaveRheologyData(
    () => mineModelQuery.refetch(),
    project?.entityId,
    project?.name,
  );

  const handleEnterRheologyData = () => {
    if (project) {
      history.push(replace(NewRheologyPageParamIdentifier, project.entityId, NewRheologyPageRoute));
    }
  };

  return (
    project && (
    <DataMultipleTransactionBox
      title="Rheology Data Sets"
      dataList={mineModel?.rheologyDataList ? mineModel?.rheologyDataList.map((dataSet) => ({ ...dataSet, entityId: dataSet.rheologyDataSetId })) : []}
      dataColumns={generateRheologyDataColumns(project.name, project.entityId, handleDeleteRheologyDataset)}
      dataListLength={3}
      errors={rheologyErrors}
      disabled={isSubmittingRheology}
      handleEnterRheologyData={handleEnterRheologyData}
    />
    )
  );
};

export default RheologyDataSetDataTransactionBox;
