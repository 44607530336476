import NewNotificationPage from './NewNotificationPage';
import NotificationsPage from './NotificationsPage';

const notificationsAdmintModule = {
  pages: [
    NotificationsPage,
    NewNotificationPage,
  ],
};

export default notificationsAdmintModule;
