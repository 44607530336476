/* eslint-disable no-mixed-operators */
interface GenerateMassBalanceFromFlowRateInputs {
  totalFlowRate: number;
  binderConcentration: number,
  tailingsSolidsDensity: number,
  binderParticleDensity: number,
  totalDryConcentrationByMass: number, // massConcentration - percentage of solids in the total weight
  carrierFluidDensity: number, // Normally water .995
}

interface GenerateMassBalanceFromFlowRateOutputs {
  totalDrySolidsDensity: number;
  slurryDensity: number;
  totalWetTonnage: number;
  totalDryTonnage: number;
  binderDryTonnage: number;
  tailingsDryTonnage: number;
  binderDryFlowRate: number;
  tailingsDryFlowRate: number;
  totalDryFlowRate: number;
  totalFlowRate: number;
}

export const totalDrySolidsDensityForFlowRate = (
  binderConcentration: number,
  tailingsSolidsDensity: number,
  binderParticleDensity: number,
) => ((1 - binderConcentration) * tailingsSolidsDensity) + (binderConcentration * (binderParticleDensity));

export const slurryDensityFromFlowRate = (
  carrierFluidDensity: number,
  totalDryConcentrationByMass: number,
  totalDrySolidsDensity: number,
) => carrierFluidDensity + (
    (carrierFluidDensity * totalDryConcentrationByMass * (
      totalDrySolidsDensity - carrierFluidDensity) / (totalDrySolidsDensity - totalDryConcentrationByMass * (totalDrySolidsDensity - carrierFluidDensity))
    )
  );

export const generateMassBalanceFromFlowRate = ({
  totalFlowRate,
  binderConcentration,
  tailingsSolidsDensity,
  binderParticleDensity,
  totalDryConcentrationByMass,
  carrierFluidDensity,
}: GenerateMassBalanceFromFlowRateInputs): GenerateMassBalanceFromFlowRateOutputs => {
  let adjustedDryConcentrationByMass = totalDryConcentrationByMass;
  let adjustedBinderConcentration = binderConcentration;

  // Check if adjustedDryConcentrationByMass is greater than 100% and divide by 100 if true
  if (adjustedDryConcentrationByMass > 1) {
    adjustedDryConcentrationByMass /= 100;
  }

  if (adjustedBinderConcentration > 1) {
    adjustedBinderConcentration /= 100;
  }

  // Density
  const totalDrySolidsDensity = totalDrySolidsDensityForFlowRate(adjustedBinderConcentration, tailingsSolidsDensity, binderParticleDensity);
  const slurryDensity = slurryDensityFromFlowRate(carrierFluidDensity, adjustedDryConcentrationByMass, totalDrySolidsDensity);

  // Tonnages
  const totalWetTonnage = totalFlowRate * (slurryDensity / 1000);
  const totalDryTonnage = totalWetTonnage * adjustedDryConcentrationByMass;
  const binderDryTonnage = totalWetTonnage * adjustedBinderConcentration;
  const tailingsDryTonnage = totalDryTonnage - binderDryTonnage;

  // flow Rates
  const binderDryFlowRate = binderDryTonnage / (binderParticleDensity / 1000);
  const tailingsDryFlowRate = tailingsDryTonnage / (tailingsSolidsDensity / 1000);
  const totalDryFlowRate = binderDryFlowRate + tailingsDryFlowRate;

  return {
    totalDrySolidsDensity,
    slurryDensity,
    totalWetTonnage,
    totalDryTonnage,
    binderDryTonnage,
    tailingsDryTonnage,
    binderDryFlowRate,
    tailingsDryFlowRate,
    totalDryFlowRate,
    totalFlowRate,
  };
};
