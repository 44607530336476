import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from 'components/ProgressButton';
import { useFormik } from 'formik';
import useCreateContentItemMutation from 'providers/api/useContentApi';
import React from 'react';

interface UserFeedback {
  feedback ?: string;
  userId: string;
  issueUrl: string;
}

interface UserFeedbackIconProps {
  userId: string;
  currentUrl: string;
}

const UserFeedbackIcon = ({ userId, currentUrl }: UserFeedbackIconProps) => {
  const [open, setOpen] = React.useState(false);
  const [feedbackComplete, setFeedbackComplete] = React.useState(false);
  const [feedbackError, setFeedbackError] = React.useState(false);

  const mutation = useCreateContentItemMutation();

  const formik = useFormik<UserFeedback>({
    initialValues: {
      feedback: '',
      userId,
      issueUrl: currentUrl,
    },
    onSubmit: async (userFeedback, { setSubmitting }) => {
      const newContentItem = {
        ContentType: 'UserFeedback',
        UserFeedback: {
          Text: {
            Text: userFeedback.feedback,
          },
          UserId: {
            Text: userFeedback.userId,
          },
          Url: {
            Text: window.location.href,
          },
        },
      };

      setSubmitting(true);
        mutation.mutate(newContentItem as any, {
          onSettled: () => {
            setSubmitting(false);
          },
          onSuccess: () => {
            setFeedbackComplete(true);
          },
          onError: () => {
            setFeedbackError(true);
          },
        });
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    dirty,
  } = formik;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setFeedbackComplete(false);
    setFeedbackError(false);
    setOpen(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" textAlign="center">
        <Tooltip title="Feedback" arrow>
          <IconButton
            onClick={handleClick}
            size="small"
            style={{ marginLeft: '8px' }}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <FeedbackIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="User Feedback Dialog"
      >
        <DialogTitle>
          Submit Feedback
        </DialogTitle>

        {!feedbackComplete && (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                Have you encountered a bug? Do you have a suggestion for how we can improve Backfill Planner?
                Complete the form below to provide feedback
              </DialogContentText>

              <Box component={Paper} p={2}>
                <TextField
                  required
                  autoFocus
                  multiline
                  rows={7}
                  fullWidth
                  id="feedback"
                  name="feedback"
                  label="Feedback"
                  value={values.feedback}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.feedback}
                  helperText={errors.feedback}
                />
              </Box>

            </DialogContent>
            <DialogActions>
              <Box mb={1}>
                <ProgressButton variant="contained" color="primary" type="submit" disabled={!dirty} loading={isSubmitting}>
                  Submit Feedback
                </ProgressButton>
              </Box>
            </DialogActions>
          </form>
        )}

        {feedbackComplete && (
          <DialogContent>
            <DialogContentText>
              <Box textAlign="center" py="8">
                {!feedbackError && <Alert severity="success">You feedback has been sent successfully. Thank you</Alert>}
                {feedbackError && <Alert severity="error">There was an error sending your feedback. Please contact the Mine Smart admin or try again later</Alert>}
              </Box>
            </DialogContentText>
          </DialogContent>
        )}

      </Dialog>
    </>
  );
};

export default UserFeedbackIcon;
