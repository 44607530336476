import {
  ContentItem,
  FileResponse
} from './api';
import { makeCreateMutation } from './useApi';

const CONTENT_ITEMS_KEY = 'content-items';

const useCreateContentItemMutation = makeCreateMutation<FileResponse, ContentItem>(
  CONTENT_ITEMS_KEY,
  (api) => (contentItem) => api.contentApi.post(contentItem, true),
  () => 'Feedback created successfully',
);

export default useCreateContentItemMutation;
